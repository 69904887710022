import React from "react";

export default function Form(props) {
  const { children, ...other } = props;
  return (
    <form
      sx={{
        width: "90%",
        margin: 1,
      }}
      noValidate
      autoComplete="off"
      {...other}
    >
      {children}
    </form>
  );
}
