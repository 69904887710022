import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Input from "../common/Input";
import Button from "../common/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { axios } from "../../helpers/Server";
import { useEffect, useState } from "react";
import { loginVerifyOTP } from "../../helpers/constants";
import { authenticate } from "../../helpers/cookies";
import CircularProgress from "@mui/material/CircularProgress";

const VerifyOtpForm = (props) => {
  const [otp, set_otp] = useState("");
  const [player_id, set_player_id] = useState("1234");
  const [is_loading, set_is_loading] = useState(false);
  const [counter, set_counter] = useState(30);
  const navigate = useNavigate();
  const { email, returnForm } = props;

  useEffect(() => {
    window.OneSignal.getUserId(function (userId) {
      set_player_id(userId ? userId : "1234");
    });
  }, []);

  useEffect(() => {
    if (counter) {
      const myInterval = setInterval(() => {
        set_counter(counter - 1);
      }, 1000);
      return () => clearInterval(myInterval);
    }
  }, [counter]);

  const resendOtp = () => {
    set_counter(30);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      set_is_loading(true);
      const login_response = await axios.post(loginVerifyOTP, {
        email,
        otp,
        // player_id,
        // device_platform: "WEB",
      });
      if (login_response.success) {
        authenticate(login_response.data, () => navigate(0));
      }
    } catch (error) {
      set_is_loading(false);
    }
  };
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ display: "flex", flexDirection: "column", width: "350px" }}
    >
      <Typography
        variant="h5"
        align="center"
        sx={{
          fontWeight: "600",
          textTransform: "uppercase",
          color: "#212121",
          fontSize: "28px",
          mb: 1.5,
        }}
      >
        OTP VERIFICATION
      </Typography>
      <Typography variant="subtitle2" align="center" sx={{ fontSize: "15px" }}>
        Enter 4 digit OTP sent to
      </Typography>
      <Typography
        variant="subtitle2"
        align="center"
        sx={{ fontSize: "15px", color: "#0098FF", mb: 2.5 }}
      >
        {email}
        <Tooltip title="edit email">
          <IconButton
            onClick={() => returnForm("")}
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <img src="/images/Iconly-Light-Edit.svg" alt="edit" />
          </IconButton>
        </Tooltip>
      </Typography>

      <Input
        inputProps={{ style: { height: "13px" } }}
        onChange={(e) => {
          if (e.target.value.toString().length <= 4) set_otp(e.target.value);
        }}
        value={otp}
        fullWidth
        name="otp"
        type="number"
      />

      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2.2,
        }}
      >
        {counter ? (
          <img src={"/images/timer.gif"} alt="timer gif" width={24} height={24} />
        ) : null}
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ color: "#B2B9CC", pl: 0.5 }}
        >
          00:{counter ? counter : "00"}
        </Typography>
      </Stack>
      <Typography variant="subtitle2" sx={{ mt: 2, color: "#7D7D7D" }}>
        Didn't received the otp?{" "}
        <Button
          variant="text"
          onClick={resendOtp}
          disabled={counter > 0}
          // disableElevation
          // disableFocusRipple
          // disableRipple
        >
          Resend OTP
        </Button>
      </Typography>

      <Button
        size="large"
        fullWidth
        onClick={handleClick}
        type="submit"
        disabled={is_loading ? true : false}
        sx={{
          mt: 5,
          p: "10px 0",
          textTransform: "capitalize",
          fontSize: "18px",
          fontWeight: "400!important",
          letterSpacing: "0.8px",
          backgroundColor: "#0098FF",
        }}
      >
        {is_loading ? <CircularProgress sx={{ color: "#fff" }} /> : "Verify"}
      </Button>
    </Stack>
  );
};

export default VerifyOtpForm;
