import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  baseUrl,
  patientList,
  updatePlayerIDAPI,
} from "../../helpers/constants";
import { isAuth, updateLocalStorage } from "../../helpers/cookies";
import { axios } from "../../helpers/Server";

export const getDoctorDashboardPatientList = createAsyncThunk(
  "getDoctorDashboardPatientList",
  async (payload, { rejectWithValue }) => {
    try {
      const { doctor_id } = payload;
      const currentDate = new Date().toISOString();
      const endpoint = `${baseUrl}${patientList}?DoctorId=${doctor_id}&page=${1}&limit=${100}&type=${"DASHBOARD"}&date=${currentDate}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updatePlayerID = createAsyncThunk(
  "updatePlayerID",
  async (payload) => {
    try {
      const { player_id } = payload;
      if (!player_id)
        throw new Error(
          "Please allow notification permission to receive important notifications."
        );
      const endpoint = `${baseUrl}${updatePlayerIDAPI}`;
      const response = await axios.post(endpoint, {
        player_id,
        device_platform: "WEB",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const initialState = {
  patient_list: {
    is_loading: false,
    player_id_loading: false,
    data: [],
  },
  doctor_id:
    isAuth().role_name === "DOCTOR"
      ? isAuth().uid
      : isAuth().role_name === "NURSE"
      ? isAuth().doctors_mapped.length > 0
        ? isAuth().doctors_mapped[0].uid
        : false
      : false,
};

export const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {
    setDoctorId: (state, action) => {
      state.doctor_id = action.payload;
    },
  },
  extraReducers: {
    //dashboard patient list
    [getDoctorDashboardPatientList.pending]: (state, action) => {
      state.patient_list.is_loading = true;
    },
    [getDoctorDashboardPatientList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.patient_list.data = data;
      state.patient_list.is_loading = false;
    },
    [getDoctorDashboardPatientList.rejected]: (state, action) => {
      state.patient_list.is_loading = false;
    },
    // Update Player ID
    [updatePlayerID.pending]: (state, action) => {
      state.player_id_loading = true;
    },
    [updatePlayerID.fulfilled]: (state, action) => {
      const data = action.payload;
      updateLocalStorage(data, () => {
        console.log("Player ID updated successfully.");
      });
      state.player_id_loading = false;
    },
    [updatePlayerID.rejected]: (state, action) => {
      state.player_id_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_dark_mode, setDoctorId } = doctorSlice.actions;

export default doctorSlice.reducer;
