import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

const KpiCount = (props) => {
    const { kpiCount, loading } = props;

    return (
        <Grid item xs={6}>
            <Paper
                elevation={0}
                sx={{ display: "flex", alignItems: "center", p: 3 }}
            >
                {
                    loading ?
                        <Skeleton variant="circular" width={57} height={57} /> :
                        <img src={kpiCount.img} width={57} height={57} alt={kpiCount.alt} />
                }
                <Box sx={{ ml: 3.5, alignItems: "flex-start", display: "flex", flexDirection: "column" }}>
                    {
                        loading ?
                            <>
                                <Skeleton variant="text" width={60} />
                                <Skeleton variant="text" width={100} />
                            </> : <>
                                <Typography variant="h5" sx={{ color: "#1D9BFF", fontWeight: "bold" }}>{kpiCount.count}</Typography>
                                <Typography variant="subtitle1" sx={{ color: "#808591" }}>{kpiCount.title}</Typography></>
                    }

                </Box>
            </Paper>

        </Grid>
    )
}

export default KpiCount