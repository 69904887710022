import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const PatientAppointment = (props) => {
  const { appointmentList } = props;
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={{ color: "#0098FF" }}>
          {moment(appointmentList.date).format("D MMM")}
        </Typography>
      </Grid>

      {appointmentList.data.map((appointment, index) => {
        const temp_date = new Date(appointment.StartTime);
        return (
          <Grid item xs={6} key={appointment.AppointmentId}>
            <Paper elevation={0} variant="outlined" sx={{ px: 2.5, py: 0.5 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ color: "#8F8F8F" }}>
                    {temp_date.toLocaleDateString("en-US", options)},{" "}
                    {temp_date.toLocaleTimeString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" sx={{ color: "#1C1C1C" }}>
                      {appointment.appointment_patient.FirstName +
                        " " +
                        appointment.appointment_patient.LastName}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#1C1C1C" }}>
                      {moment().diff(
                        appointment.appointment_patient.BirthDate,
                        "years"
                      )}{" "}
                      Yrs
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      })}
    </>
  );
};

export default PatientAppointment;
