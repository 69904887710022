import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  baseUrl,
  supportDashboardKpi,
  myTeamDoctorList,
  myTeamNurseList,
  addDoctorsUrl,
  getSpecialityListUrl,
  addNurseUrl,
  getUserDetailsUrl,
  updateDoctorsUrl,
  updateNurseUrl,
} from "../../helpers/constants";
import { isAuth } from "../../helpers/cookies";
import { axios } from "../../helpers/Server";

export const getSupportDashboardKpi = createAsyncThunk(
  "getSupportDashboardKpi",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${supportDashboardKpi}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getMyTeamDoctorList = createAsyncThunk(
  "getMyTeamDoctorList",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${myTeamDoctorList}?&page=${1}&limit=${100}&ignore_nurse=true`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getMyTeamNurseList = createAsyncThunk(
  "getMyTeamNurseList",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${myTeamNurseList}?&page=${1}&limit=${100}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addDoctors = createAsyncThunk(
  "addDoctors",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${addDoctorsUrl}`;
      const response = await axios.post(endpoint, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateDoctors = createAsyncThunk(
  "updateDoctors",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${updateDoctorsUrl}`;
      const response = await axios.put(endpoint, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${getUserDetailsUrl}?user_id=${payload.uid}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addNurses = createAsyncThunk(
  "addNurses",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${addNurseUrl}`;
      const response = await axios.post(endpoint, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);


export const updateNurses = createAsyncThunk(
  "updateNurses",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${updateNurseUrl}`;
      const response = await axios.put(endpoint, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getSpecialityList = createAsyncThunk(
  "getSpecialityList",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${getSpecialityListUrl}?page=0&limit=20`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const initialState = {
  dashboard_kpi_count: [],
  kpi_is_loading: false,
  dashboard_doctor_list: [],
  doctor_list_is_loading: false,
  dashboard_nurse_list: [],
  nurse_list_is_loading: false,
  add_doctors_is_loading: false,
  speciality_list: [],
  speciality_is_loading: false,
  user_details: false,
  get_user_is_loading: true,
};

export const dashboardtSlice = createSlice({
  name: "supportDashboard",
  initialState,
  reducers: {
    setDoctorId: (state, action) => {
      state.doctor_id = action.payload;
    },
  },
  extraReducers: {
    //dashboard kpi count
    [getSupportDashboardKpi.pending]: (state, action) => {
      state.kpi_is_loading = true;
    },
    [getSupportDashboardKpi.fulfilled]: (state, action) => {
      let data = action.payload;
      state.dashboard_kpi_count = data;
      state.kpi_is_loading = false;
    },
    [getSupportDashboardKpi.rejected]: (state, action) => {
      state.kpi_is_loading = false;
    },

    //dashboard doctor list
    [getMyTeamDoctorList.pending]: (state, action) => {
      state.doctor_list_is_loading = true;
    },
    [getMyTeamDoctorList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.dashboard_doctor_list = data;
      state.doctor_list_is_loading = false;
    },
    [getMyTeamDoctorList.rejected]: (state, action) => {
      state.doctor_list_is_loading = false;
    },

    //dashboard nurse list
    [getMyTeamNurseList.pending]: (state, action) => {
      state.nurse_list_is_loading = true;
    },
    [getMyTeamNurseList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.dashboard_nurse_list = data;
      state.nurse_list_is_loading = false;
    },
    [getMyTeamNurseList.rejected]: (state, action) => {
      state.nurse_list_is_loading = false;
    },

    //add doctors
    [addDoctors.pending]: (state, action) => {
      state.add_doctors_is_loading = true;
    },
    [addDoctors.fulfilled]: (state, action) => {
      state.add_doctors_is_loading = false;
      window.location = "/support/dashboard";
    },
    [addDoctors.rejected]: (state, action) => {
      state.add_doctors_is_loading = false;
    },

    [updateDoctors.pending]: (state, action) => {
      state.add_doctors_is_loading = true;
    },
    [updateDoctors.fulfilled]: (state, action) => {
      state.add_doctors_is_loading = false;
      window.location = "/support/dashboard";
    },
    [updateDoctors.rejected]: (state, action) => {
      state.add_doctors_is_loading = false;
    },

    //add doctors
    [getUserDetails.pending]: (state, action) => {
      state.get_user_is_loading = true;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      let data = action.payload;
      state.user_details = data;
      state.get_user_is_loading = false;
    },
    [getUserDetails.rejected]: (state, action) => {
      state.get_user_is_loading = false;
    },

    //add nurse
    [addNurses.pending]: (state, action) => {
      state.add_doctors_is_loading = true;
    },
    [addNurses.fulfilled]: (state, action) => {
      state.add_doctors_is_loading = false;
      window.location = "/support/dashboard";
    },
    [addNurses.rejected]: (state, action) => {
      state.add_doctors_is_loading = false;
    },

    //add nurse
    [updateNurses.pending]: (state, action) => {
      state.add_doctors_is_loading = true;
    },
    [updateNurses.fulfilled]: (state, action) => {
      state.add_doctors_is_loading = false;
      window.location = "/support/dashboard";
    },
    [updateNurses.rejected]: (state, action) => {
      state.add_doctors_is_loading = false;
    },

    //speciality list
    [getSpecialityList.pending]: (state, action) => {
      state.speciality_is_loading = true;
    },
    [getSpecialityList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.speciality_list = data;
      state.speciality_is_loading = false;
    },
    [getSpecialityList.rejected]: (state, action) => {
      state.speciality_is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_dark_mode } = dashboardtSlice.actions;

export default dashboardtSlice.reducer;
