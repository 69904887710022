import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import PatientAppointment from "../../components/doctor/history/PatientAppointment";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentHistoryList } from "../../redux/doctor/history";
import { authDoctorId, isAuth } from "../../helpers/cookies";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

const HistoryContainer = () => {
  let currentDate = moment();
  const [value, setValue] = useState([
    moment(currentDate).subtract(3, "day").toDate(),
    currentDate,
  ]);

  const { doctor_id } = useSelector((state) => state.doctor);
  const { appointment_history } = useSelector((state) => state.history);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAppointmentHistoryList({ doctor_id: doctor_id, dateRange: value })
    );
  }, [dispatch, value, doctor_id]);

  const renderAppointmentDetails = appointment_history?.data.map(
    (appointmentList, i) => (
      <PatientAppointment appointmentList={appointmentList} key={i} />
    )
  );

  const handleDateRange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "20px" }}>
            Appointment History
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              calendars={1}
              value={value}
              onChange={(newValue) => handleDateRange(newValue)}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    {...startProps}
                    label=""
                    variant="outlined"
                    size="small"
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField
                    {...endProps}
                    label=""
                    variant="outlined"
                    size="small"
                  />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
      {appointment_history.is_loading ? (
        <Grid container item justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, borderRadius: "10px" }} elevation={0}>
              <Grid container item spacing={2}>
                {appointment_history?.data.length ? (
                  renderAppointmentDetails
                ) : (
                  <Grid item xs={12}>
                    <Typography align="center">Data Not Found</Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default HistoryContainer;
