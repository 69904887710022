import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

const PatientCount = (props) => {
  const { patientsDetail, loading } = props;

  return (
    <Grid item xs={4}>
      <Paper elevation={0} sx={{ display: "flex", alignItems: "center", p: 3 }}>
        {loading ? (
          <Skeleton variant="circular" width={57} height={57} />
        ) : (
          <img
            src={patientsDetail.img}
            width={57}
            height={57}
            alt={patientsDetail.alt}
          />
        )}
        <Box
          sx={{
            ml: 3.5,
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <Skeleton variant="text" width={40} />
          ) : (
            <Typography
              variant="h5"
              sx={{ color: "#1D9BFF", fontWeight: "bold" }}
            >
              {patientsDetail.count}
            </Typography>
          )}
          {loading ? (
            <Skeleton variant="text" width={80} />
          ) : (
            <Typography variant="subtitle1" sx={{ color: "#808591" }}>
              {patientsDetail.title}
            </Typography>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default PatientCount;
