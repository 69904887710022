import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl, appointmentHistory } from "../../helpers/constants";
import { axios } from "../../helpers/Server";
import _ from "lodash";

export const getAppointmentHistoryList = createAsyncThunk(
  "getAppointmentHistoryList",
  async (payload, { rejectWithValue }) => {
    try {
      const { doctor_id, dateRange } = payload;
      const endpoint = `${baseUrl}${appointmentHistory}?DoctorId=${doctor_id}&from_date=${new Date(dateRange[0]).toISOString()}&to_date=${new Date(dateRange[1]).toISOString()}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const initialState = {
  appointment_history: {
    is_loading: false,
    data: [],
  },
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: {
    //Appointment history
    [getAppointmentHistoryList.pending]: (state, action) => {
      state.appointment_history.is_loading = true;
    },
    [getAppointmentHistoryList.fulfilled]: (state, action) => {
      let data = action.payload;
      const new_result = _.chain(data)
        .groupBy(({ StartTime }) => StartTime)
        .map((value, key) => ({ date: key, data: value }))
        .value();
      state.appointment_history.data = new_result;
      state.appointment_history.is_loading = false;
    },
    [getAppointmentHistoryList.rejected]: (state, action) => {
      state.appointment_history.is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_dark_mode } = historySlice.actions;

export default historySlice.reducer;
