import React, { useEffect, useState } from "react";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "../../../components/common/Table";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiButton from "../../common/Button";

const FuturePatientList = (props) => {
  function createData(
    queueNo,
    patientName,
    Age,
    Time,
    Checkup,
    appointment_data
  ) {
    return {
      queueNo,
      patientName,
      Age,
      Time,
      Checkup,
      appointment_data,
    };
  }

  const [rows, setRows] = useState([]);

  const columns = [
    {
      title: "Queue No.",
    },
    {
      title: "Patient Name",
    },
    {
      title: "Age",
    },
    {
      title: "Time",
    },
    {
      title: "Appointment",
    },
  ];

  useEffect(() => {
    let finalData = [];
    if (props.rows) {
      props.rows.map(function (dd) {
        let finalStatus = "";
        if (dd.is_completed === true) {
          finalStatus = "COMPLETED";
        } else if (dd.Classification === 1 && dd.ConfirmationStatus === 1 && !dd.AppointmentArrivalStatus) {
          finalStatus = "PATIENT_ARRIVED";
        } else if (dd.next_in_q === true) {
          finalStatus = "NEXT_IN_Q";
        } else if (dd.Classification === 1 && dd.ConfirmationStatus === 1 && dd.AppointmentArrivalStatus === '2') {
          finalStatus = "CONFIRMED";
        } else if (dd.Classification === 1 && dd.ConfirmationStatus === 0) {
          finalStatus = "PENDING";
        } else if (dd.Classification === 32) {
          finalStatus = "CANCELLED";
        } else if (dd.Classification === 4) {
          finalStatus = "RESCHEDULED";
        } else if (dd.ConfirmationStatus !== 0 && dd.ConfirmationStatus !== 1) {
          finalStatus = "REMINDER";
        }
        finalData.push(
          createData(
            finalStatus === "CONFIRMED" || finalStatus === "NEXT_IN_Q"
              ? dd.queue_no
              : "-",
            dd.appointment_patient?.FirstName +
            " " +
            dd.appointment_patient?.LastName,
            dd.appointment_patient?.BirthDate
              ? moment().diff(dd.appointment_patient.BirthDate, "years")
              : 0,
            moment(dd.StartTime).format("hh:mm A"),
            finalStatus,
            dd
          )
        );
        return true;
      });
      setRows(finalData);
    }
  }, [props.rows]);

  const renderTableData = rows.map((row, index) => (
    <TableRow
      key={row.queueNo}
      sx={{ "td, th": { border: 0, padding: "10px" } }}
    >
      <TableCell align="center" component="th" scope="row">
        {row.queueNo}
      </TableCell>
      <TableCell
        align="center"
        onClick={() => props.viewPatient(row.appointment_data)}
        sx={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {row.patientName}
      </TableCell>
      <TableCell align="center">{row.Age}</TableCell>
      <TableCell align="center">{row.Time}</TableCell>
      <TableCell align="center">
        {row.Checkup === "CONFIRMED" ? (
          <MuiButton
            disableElevation
            variant="outlined"
            disabled
            sx={{ borderRadius: "50px" }}
          >
            Arrived{" "}
          </MuiButton>
        ) : row.Checkup === 'PATIENT_ARRIVED' ? (
          <MuiButton
            onClick={() =>
              props.updateAppointmentAction(row.appointment_data, "PATIENT_ARRIVED")
            }
            disableElevation
            variant="outlined"
            // disabled
            sx={{ borderRadius: "50px" }}
          >
            Check In{" "}
          </MuiButton>
        ) : row.Checkup === "NEXT_IN_Q" ? (
          <MuiButton
            onClick={() =>
              props.updateAppointmentAction(row.appointment_data, "COMPLETED")
            }
            variant="outlined"
            sx={{ borderRadius: "50px" }}
          >
            Mark as complete{" "}
          </MuiButton>
        ) : row.Checkup === "PENDING" ? (
          <MuiButton
            onClick={() =>
              props.updateAppointmentAction(
                row.appointment_data,
                "NOTIFY_CONFIRMATION"
              )
            }
            variant="outlined"
            sx={{ borderRadius: "50px" }}
          >
            Send reminder{" "}
          </MuiButton>
        ) : row.Checkup === "RESCHEDULED" ? (
          <MuiButton
            onClick={() => props.rescheduleAppointment(row.appointment_data)}
            color="primary"
            sx={{ borderRadius: "50px" }}
          >
            Requested Reschedule
          </MuiButton>
        ) : row.Checkup === "COMPLETED" ? (
          <MuiButton
            disableElevation
            variant="outlined"
            color="success"
            disableRipple
            disableFocusRipple
            sx={{ borderRadius: "50px" }}
          >
            Completed
          </MuiButton>
        ) : row.Checkup === "CANCELLED" ? (
          <MuiButton
            disableElevation
            variant="outlined"
            color="error"
            disableRipple
            disableFocusRipple
            sx={{ borderRadius: "50px" }}
          >
            Cancelled
          </MuiButton>
        ) : row.Checkup === "REMINDER" ? (
          <MuiButton
            disableElevation
            variant="outlined"
            color="success"
            disableRipple
            disableFocusRipple
            sx={{ borderRadius: "50px" }}
          >
            Reminder Sent
          </MuiButton>
        ) : null}
      </TableCell>
    </TableRow>
  ));
  return (
    <Paper sx={{ p: 2, color: "#071B2A", fontWeight: "400" }} elevation={0}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Patients List
      </Typography>
      {/* {rows && rows.length > 0 ? ( */}
      <Table
        loading={props.is_loading}
        columns={columns}
        tableData={renderTableData}
      />
      {/* ) : null} */}
    </Paper>
  );
};

export default FuturePatientList;
