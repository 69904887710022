import React from 'react';
import Table from "../../../components/common/Table";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MuiButton from '../../../components/common/Button';

const MyTeam = (props) => {
    const { teamDetails, openChat, loading } = props;

    const handleOpenChat = (ProviderId) => {
        openChat(ProviderId, "MYTEAM");
    }

    const columns = [
        {
            title: 'Sr.No.',
        },
        {
            title: 'Doctors Name',
        },
        {
            title: 'Email Id',
        },
        {
            title: 'Phone',
        },
        {
            title: 'City',
        },
        {
            title: 'Chat',
        },
    ]

    const renderTableData = teamDetails.length ? teamDetails.map((row, index) => {
        const { ProviderId } = row;

        return (
            <TableRow
                key={index}
                sx={{ 'td, th': { border: 0, padding: "10px" } }}
            >
                <TableCell align="center" component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">{row.FirstName+" "+row.LastName}</TableCell>
                <TableCell align="center">{row.Email}</TableCell>
                <TableCell align="center">{row.Phone}</TableCell>
                <TableCell align="center">{row.City}</TableCell>
                <TableCell align="center">
                    <MuiButton disableElevation variant="outlined" sx={{ borderRadius: "50px" }} onClick={() => handleOpenChat(ProviderId)}>Chat </MuiButton>
                </TableCell>
            </TableRow>
        )
    }) : (
        <TableRow sx={{ 'td, th': { border: 0, padding: "10px" } }}>
            <TableCell align="center">Data not found</TableCell>
        </TableRow>
    )

    return (
        <Table columns={columns} tableData={renderTableData} loading={loading} />
    )
}

export default MyTeam;