import { Routes, Route } from "react-router-dom";

// Views
import DashboardView from "../pages/support/Dashboard.js";
import AddDoctorsView from "../pages/support/AddDoctors.js";
import AddNursesView from "../pages/support/AddNurses.js";

const SupportRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<DashboardView />} />
      <Route exact path="/dashboard" element={<DashboardView />} />
      <Route exact path="/add-doctors" element={<AddDoctorsView />} />
      {/* <Route exact path="/add-nurses" element={<AddNursesView />} /> */}
    </Routes>
  );
};

export default SupportRoutes;
