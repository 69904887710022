import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { CometChatUI } from "../../cometchat";

const CometChatCustomUI = () => {
  const { chat_window_uid, chat_window_guid } = useSelector(
    (state) => state.feedback
  );
  return (
    <Paper
      elevation={0}
      sx={{ zIndex: 9999, position: "fixed", bottom: 80, right: 18 }}
    >
      <div style={{ width: "660px", height: "70vh" }}>
        <CometChatUI
          chatWithGroup={chat_window_guid}
          chatWithUser={chat_window_uid}
        />
      </div>
    </Paper>
  );
};

export default CometChatCustomUI;
