import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Input from "../common/Input";
import Button from "../common/Button";
import Stack from "@mui/material/Stack";
import { axios } from "../../helpers/Server";
import { loginSendOTP } from "../../helpers/constants";
import CircularProgress from '@mui/material/CircularProgress';

const LoginForm = ({ returnForm }) => {
  const [email, set_email] = useState("");
  const [is_loading, set_is_loading] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      set_is_loading(true);
      const data = await axios.post(loginSendOTP, { email });
      if (data.success) {
        returnForm(email);
      }
    } catch (error) {
      set_is_loading(false);
    }
  };

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ display: "flex", flexDirection: "column", width: "350px" }}
      >
        <Typography
          variant="h5"
          align="center"
          sx={{
            fontWeight: "600",
            textTransform: "uppercase",
            color: "#212121",
            fontSize: "28px",
            mb: 1.5,
          }}
        >
          Login
        </Typography>

        <Typography
          variant="subtitle2"
          align="center"
          sx={{ fontSize: "15px", color: "#7D7D7D", mb: 2.5 }}
        >
          Welcome Back
        </Typography>

        <Typography
          variant="subtitle2"
          alignSelf="start"
          sx={{ color: "#707070", mb: 1.2 }}
        >
          Email
        </Typography>

        <Input
          placeholder="Enter email address"
          inputProps={{ style: { height: "13px" } }}
          name="email"
          id="email"
          value={email}
          onChange={(e) => set_email(e.target.value)}
          fullWidth
        />

        <Button
          size="large"
          fullWidth
          disabled={is_loading ? true : false}
          type="submit"
          onClick={handleClick}
          sx={{
            mt: 5,
            p: "10px 0",
            textTransform: "capitalize",
            fontSize: "18px",
            fontWeight: "400!important",
            letterSpacing: "0.8px",
            backgroundColor: "#0098FF",
          }}
        >
          {
            is_loading ? <CircularProgress sx={{ color: "#fff" }} /> : "Continue"
          }
        </Button>
        {/* {is_loading ? <LinearIndeterminate /> : null} */}
      </Stack>
    </>
  );
};

export default LoginForm;
