import React, { useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Form from "../../components/common/Form";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    addDoctors,
    getSpecialityList,
    updateDoctors,
} from "../../redux/support/dashboard";
import { CircularProgress, FormHelperText } from "@mui/material";
import { ThumbUpAlt } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from "react-router-dom";
import CountriesSelect from "../../components/common/CountriesSelect";

const AddDoctorsContainer = () => {
    // const [city, setCity] = React.useState('');
    const { state } = useLocation();
    const dispatch = useDispatch();

    // const { speciality_list } = useSelector((state) => state.supportDashboard);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: state?.user_details,
    });

    const onSubmit = (data) => {
        if (state?.user_details) {
            data.uid = state.uid;
            dispatch(updateDoctors(data));
        } else {
            dispatch(addDoctors(data));
        }
    };

    // useEffect(() => {
    //     dispatch(getSpecialityList());
    // }, [dispatch]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                    }}
                >
                    <Typography variant="h5" sx={{ fontSize: "20px" }}>
                        Add Doctors / Nurse
                    </Typography>
                </Box>
            </Grid>
            {state && state.doctor_id ? (
                <Grid container item justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <PersonOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        name="FirstName"
                                        {...register("FirstName", {
                                            required: "First Name is required",
                                        })}
                                        {...(errors.FirstName && {
                                            error: true,
                                            helperText: errors.FirstName.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <PersonOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        name="LastName"
                                        {...register("LastName", {
                                            required: "Last Name is required",
                                        })}
                                        {...(errors.LastName && {
                                            error: true,
                                            helperText: errors.LastName.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <EmailOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        name="Email"
                                        {...register("Email", {
                                            required: "Email is required",
                                            pattern: {
                                                value:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "Please enter a valid email",
                                            },
                                        })}
                                        {...(errors.Email && {
                                            error: true,
                                            helperText: errors.Email.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Phone"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <PhoneAndroidIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("Phone", {
                                            required: "Phone is required",
                                        })}
                                        {...(errors.Phone && {
                                            error: true,
                                            helperText: errors.Phone.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Address Line 1"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <FmdGoodIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("Address1", {
                                            required: "Address line 1 is required",
                                        })}
                                        {...(errors.Address1 && {
                                            error: true,
                                            helperText: errors.Address1.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Address Line 2"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <FmdGoodIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("Address2", {
                                            required: "Address line 2 is required",
                                        })}
                                        {...(errors.Address2 && {
                                            error: true,
                                            helperText: errors.Address2.message,
                                        })}
                                    />
                                </FormControl>

                                {/* <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <CountriesSelect
                                        name={"country_code"}
                                        label={"Country Code"}
                                        control={control}
                                        values={["aaa", "bbb", "ccc"]}
                                    />
                                </FormControl> */}

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="City"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <FmdGoodIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("City", {
                                            required: "City is required",
                                        })}
                                        {...(errors.City && {
                                            error: true,
                                            helperText: errors.City.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="State"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <FmdGoodIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("State", {
                                            required: "State is required",
                                        })}
                                        {...(errors.State && {
                                            error: true,
                                            helperText: errors.State.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Zipcode"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <FmdGoodIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("Zipcode", {
                                            required: "Zipcode is required",
                                        })}
                                        {...(errors.Zipcode && {
                                            error: true,
                                            helperText: errors.Zipcode.message,
                                        })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <InputLabel id="Sex">Sex</InputLabel>
                                    <Select
                                        labelId="Sex"
                                        id="demo-simple-select"
                                        label="Sex"
                                        {...(errors.Sex ? { error: true } : null)}
                                        defaultValue={"M"}
                                        startAdornment={
                                            <InputAdornment
                                                position="start"
                                                sx={{ color: "#B2B9CC" }}
                                            >
                                                <PersonIcon />
                                            </InputAdornment>
                                        }
                                        {...register("Sex", {
                                            required: "Sex is required",
                                        })}
                                    >
                                        <MenuItem value={"M"}>
                                            Male
                                        </MenuItem>
                                        <MenuItem value={"F"}>
                                            Female
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                        {errors.Sex ? errors.Sex.message : null}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <InputLabel id="type">Type</InputLabel>
                                    <Select
                                        labelId="type"
                                        id="demo-simple-select"
                                        label="Type"
                                        {...(errors.type ? { error: true } : null)}
                                        defaultValue={"DOCTOR"}
                                        startAdornment={
                                            <InputAdornment
                                                position="start"
                                                sx={{ color: "#B2B9CC" }}
                                            >
                                                <PersonIcon />
                                            </InputAdornment>
                                        }
                                        {...register("type", {
                                            required: "Type is required",
                                        })}
                                    >
                                        <MenuItem value={"DOCTOR"}>
                                            Doctor
                                        </MenuItem>
                                        <MenuItem value={"NURSE"}>
                                            Nurse
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                        {errors.type ? errors.type.message : null}
                                    </FormHelperText>
                                </FormControl>

                                {/* <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Experience"
                                        variant="outlined"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ color: "#B2B9CC" }}
                                                >
                                                    <EventNoteIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register("experience", {
                                            required: "Experience is required",
                                        })}
                                        {...(errors.experience && {
                                            error: true,
                                            helperText: errors.experience.message,
                                        })}
                                    />
                                </FormControl> */}

                                {/* <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <InputLabel id="speciality_id">Speciality</InputLabel>
                                    <Select
                                        labelId="speciality_id"
                                        id="demo-simple-select"
                                        label="Speciality"
                                        {...(errors.speciality ? { error: true } : null)}
                                        defaultValue={
                                            getValues("speciality_id")
                                                ? getValues("speciality_id")
                                                : " "
                                        }
                                        startAdornment={
                                            <InputAdornment
                                                position="start"
                                                sx={{ color: "#B2B9CC" }}
                                            >
                                                <ThumbUpAlt />
                                            </InputAdornment>
                                        }
                                        {...register("speciality_id", {
                                            required: "Speciality is required",
                                        })}
                                    >
                                        {speciality_list.map(function (dd, key) {
                                            return (
                                                <MenuItem value={dd.speciality_id} key={key}>
                                                    {dd.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText error>
                                        {errors.speciality ? errors.speciality.message : null}
                                    </FormHelperText>
                                </FormControl> */}

                                {/* <FormControl
                                    sx={{ width: "30ch", my: 1, mr: 2, backgroundColor: "#fff" }}
                                >
                                    <InputLabel id="tags">Tag</InputLabel>
                                    <Select
                                        labelId="tags"
                                        id="tag-simple-select"
                                        defaultValue={getValues("tags") ? getValues("tags") : []}
                                        label="Tags"
                                        multiple
                                        {...(errors.tags ? { error: true } : null)}
                                        startAdornment={
                                            <InputAdornment
                                                position="start"
                                                sx={{ color: "#B2B9CC" }}
                                            >
                                                <ThumbUpAlt />
                                            </InputAdornment>
                                        }
                                        {...register("tags", {
                                            required: "Tags is required",
                                        })}
                                    >
                                        {speciality_list.map(function (dd, key) {
                                            return (
                                                <MenuItem value={dd.speciality_id} key={key}>
                                                    {dd.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText error>
                                        {errors.tags ? errors.tags.message : null}
                                    </FormHelperText>
                                </FormControl> */}
                            </Box>
                        </Grid>
                        <Grid item xs={3.4}>
                            <Button
                                variant="contained"
                                type="submit"
                                disableElevation
                                size="large"
                                fullWidth
                            >
                                {state?.user_details ? "Update Doctors" : "Add Doctors"}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Grid>
    );
};

export default AddDoctorsContainer;
