import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch, useSelector } from "react-redux";
import { syncEaglePatient } from '../../redux/features/feedback';

const SyncEagle = () => {
    const { eagle_patient_is_loading } = useSelector((state) => state.feedback);
    const dispatch = useDispatch();

    const handleSyncEagle = () => {
        dispatch(syncEaglePatient())
    }

    return (
        <LoadingButton
            loading={eagle_patient_is_loading}
            loadingPosition="start"
            startIcon={<SyncIcon />}
            variant="outlined"
            onClick={handleSyncEagle}
        >
            Eagle soft sync
        </LoadingButton>
    )
}

export default SyncEagle;