import { configureStore } from "@reduxjs/toolkit";
import feedbackReducer from "./features/feedback";
import doctorReducer from "../redux/doctor/dashboard";
import historyReducer from "../redux/doctor/history";
import scheduleReducer from "../redux/doctor/schedule";
import chatReducer from "../redux/doctor/chat";
import supportDashboardReducer from "../redux/support/dashboard";
import axiosInterceptor from "../helpers/Server";

const store = configureStore({
  reducer: {
    feedback: feedbackReducer,
    doctor: doctorReducer,
    history: historyReducer,
    schedule: scheduleReducer,
    chat: chatReducer,
    supportDashboard: supportDashboardReducer,
  },
});

axiosInterceptor(store.dispatch);

export { store };
