import React from "react";
import BaseLayout from "../../layout/DoctorBaseLayout";
import DoctorRoutes from "../../routes/Doctor";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

const doctorModules = [
  {
    id: "dashboard",
    title: "Dashboard",
    logo: <HomeIcon />,
    link: "/doctor/dashboard",
  },
  {
    id: "schedule",
    title: "Appointments",
    logo: <CalendarTodayIcon />,
    link: "/doctor/schedule",
  },
  {
    id: "history",
    title: "History",
    logo: <HistoryIcon />,
    link: "/doctor/history",
  },
  {
    id: "chat",
    title: "Chat",
    logo: <ChatBubbleOutlineOutlinedIcon />,
    link: "/doctor/chat",
  },
  {
    id: "profile",
    title: "My Profile",
    logo: <PermIdentityOutlinedIcon />,
    link: "/doctor/profile",
  },
];

const DoctorContainer = () => {
  return (
    <div>
      <BaseLayout modules={doctorModules} ChildComponent={DoctorRoutes} />
    </div>
  );
};

export default DoctorContainer;
