import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import countries from "../../helpers/countries";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const CountriesSelect = ({ label, name, values = [], control }) => {
  const options = countries.map((value) => ({
    label: "+" + value.phone + " - " + value.label,
    value: "+" + value.phone,
  }));

  return (
    <div>
      <Controller
        name={"country_code"}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              options={options}
              placeholder={"Country Code"}
              isMulti={false}
              onChange={(options) => {
                onChange(options.value);
              }}
              onBlur={onBlur}
              value={options.filter((option) => value?.includes(option.value))}
              defaultValue={options.filter(
                (option) => value?.includes(option.value) || "+1"
              )}
              styles={customStyles}
            />
          );
        }}
      />
    </div>
  );
};

export default CountriesSelect;
