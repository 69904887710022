import { CometChat } from "@cometchat-pro/chat";
import { cometChatConfig } from "../helpers/constants";

const appID = cometChatConfig.appID;
const region = cometChatConfig.appRegion;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully");
    // You can now call login function.
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);

export default CometChat;
