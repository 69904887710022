import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import moment from "moment";

import PatientCount from "../../components/doctor/dashboard/PatientCount";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorDashboardPatientList } from "../../redux/doctor/dashboard";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import DashboardHeader from "../../components/doctor/dashboard/DashboardHeader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const barChartLabel = [
  "23 Mar",
  "24 Mar",
  "25 Mar",
  "26 Mar",
  "27 Mar",
  "28 Mar",
  "29 Mar",
  "30 Mar",
  "31 Mar",
  "1 Apr",
  "2 Apr",
  "3 Apr",
  "4 Apr",
];
const barChartData = {
  labels: barChartLabel,
  datasets: [
    {
      label: "Appointments",
      data: barChartLabel.map(() =>
        Math.floor(Math.random() * (1000 - 1 + 1) + 1)
      ),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const data = [12, 19, 3, 10];
const labels = ["Cancelled", "Confirmed", "Rescheduled", "No-show"];

const customLabels = labels.map((label, index) => `${label}: ${data[index]}`);

const pieChartData = {
  labels: customLabels,
  datasets: [
    {
      label: "No. of Appointments",
      data,
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(255, 206, 100, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(255, 206, 100, 0.2)",
      ],
      borderWidth: 1,
    },
  ],
};

const stackedBarData = {
  labels: barChartLabel,
  datasets: [
    {
      label: "Appointments",
      data: barChartLabel.map(() =>
        Math.floor(Math.random() * (1000 - 1 + 1) + 1)
      ),
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "No Shows",
      data: barChartLabel.map(() =>
        Math.floor(Math.random() * (1000 - 1 + 1) + 1)
      ),
      backgroundColor: "rgb(75, 192, 192)",
    },
  ],
};

const DashboardContainer = () => {
  const { patient_list, doctor_id } = useSelector((state) => state.doctor);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDoctorDashboardPatientList({ doctor_id: doctor_id }));
  }, [dispatch, doctor_id]);

  const patientsDetails = [
    {
      img: "/images/patients.svg",
      alt: "total patient",
      count: patient_list.data?.kpi ? patient_list.data.kpi.total_patients : 0,
      title: "Total Patients",
    },
    {
      img: "/images/completed_patient.svg",
      alt: "completed patient",
      count: patient_list.data?.kpi
        ? patient_list.data.kpi.completed_patients
        : 0,
      title: "Completed Patients",
    },
    {
      img: "/images/waiting_patient.svg",
      alt: "waiting patient",
      count: patient_list.data?.kpi
        ? patient_list.data.kpi.waiting_patients
        : 0,
      title: "Waiting Patient",
    },
  ];
  const renderPatientsCard = patientsDetails.map((patientsDetail, index) => (
    <PatientCount
      patientsDetail={patientsDetail}
      key={index}
      loading={patient_list.is_loading}
    />
  ));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <DashboardHeader
            title="Dashboard"
            time={moment().format("DD MMM YYYY")}
          />
        </Box>
      </Grid>
      <Grid container item spacing={1}>
        {renderPatientsCard}
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Typography variant="h5">Total Appointment and No Shows</Typography>
            <Bar data={stackedBarData} />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} item>
        <Grid item xs={8}>
          <Paper elevation={0}>
            <Bar data={barChartData} />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0}>
            <Typography variant="h5">Past Appointments Records</Typography>
            <Pie
              data={pieChartData}
              legend={{ display: true, position: "right" }}
              datalabels={{
                display: true,
                color: "white",
              }}
              tooltips={{
                backgroundColor: "#5a6e7f",
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardContainer;
