import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  baseUrl,
  patientList,
  futureAppointmentDateCount,
  createAppointmentUrl,
  updateAppointmentUrl,
  fetchPatientDocumentsUrl,
  removeRequestDocsUrl,
  hospitalTestListUrl,
  requestDocsUrl,
  getPatientNotesUrl,
  addPatientNotesUrl,
  createPatientUrl,
} from "../../helpers/constants";
import { axios } from "../../helpers/Server";

const initialState = {
  schedule_appointment_data: [],
  schedule_appointment_is_loading: true,
  schedule_appointment_date_data: [],
  schedule_appointment_date_is_loading: true,

  fetch_document_data: [],
  fetch_request_document_data: [],
  fetch_document_is_loading: true,

  hospital_data: [],
  hospital_list_is_loading: true,

  patient_notes: [],
  patient_notes_count: [],
  get_notes_is_loading: true,
  add_notes_is_loading: false,

  create_appointment_is_loading: true,
  create_patient_is_loading: true,
  update_appointment_is_loading: true,
  request_is_loading: true,
  // schedule_appointment: {
  //   is_loading: false,
  //   data: [],
  // },
  // schedule_appointment_date: {
  //   is_loading: false,
  //   data: [],
  // },
};

export const getScheduleAppointmentList = createAsyncThunk(
  "getScheduleAppointmentList",
  async (payload, { rejectWithValue }) => {
    try {
      const { doctor_id, appointment_date } = payload;
      const endpoint = `${baseUrl}${patientList}?DoctorId=${doctor_id}&page=${1}&limit=${100}&type=${"SCHEDULE"}&date=${appointment_date}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getScheduleAppointmentDate = createAsyncThunk(
  "getScheduleAppointmentDate",
  async (payload, { rejectWithValue }) => {
    try {
      const date = new Date().toISOString();
      const { doctor_id } = payload;
      const endpoint = `${baseUrl}${futureAppointmentDateCount}?DoctorId=${doctor_id}&today=${date}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createAppointment = createAsyncThunk(
  "createAppointment",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${createAppointmentUrl}`;
      const response = await axios.post(endpoint, payload);
      dispatch(getScheduleAppointmentDate(payload));
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createPatient = createAsyncThunk(
  "createPatient",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${createPatientUrl}`;
      const response = await axios.post(endpoint, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateAppointment = createAsyncThunk(
  "updateAppointment",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${updateAppointmentUrl}`;
      const response = await axios.put(endpoint, payload.update);
      dispatch(getScheduleAppointmentDate(payload.refresh));
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const fetchPatientDocuments = createAsyncThunk(
  "fetchPatientDocuments",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${fetchPatientDocumentsUrl}?guid=${payload}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const removeRequestDocs = createAsyncThunk(
  "removeRequestDocs",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${removeRequestDocsUrl}`;
      const response = await axios.post(endpoint, {
        request_ids: [payload.id],
      });
      dispatch(fetchPatientDocuments(payload.patient_id));
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const hospitalTestList = createAsyncThunk(
  "hospitalTestList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${hospitalTestListUrl}?doctor_id=${payload}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const requestDocs = createAsyncThunk(
  "requestDocs",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${requestDocsUrl}`;
      const response = await axios.post(endpoint, payload);
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const getPatientNotes = createAsyncThunk(
  "getPatientNotes",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${getPatientNotesUrl}`;
      const response = await axios.get(endpoint, { params: payload });
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const addPatientNotes = createAsyncThunk(
  "addPatientNotes",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `${baseUrl}${addPatientNotesUrl}`;
      const response = await axios.post(endpoint, payload.add);
      dispatch(getPatientNotes(payload.refresh));
      return response.data;
    } catch (error) {
      console.log(error, "error");
      rejectWithValue(error);
    }
  }
);

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},
  extraReducers: {
    //Appointment history
    [getScheduleAppointmentList.pending]: (state, action) => {
      state.schedule_appointment_is_loading = true;
    },
    [getScheduleAppointmentList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.schedule_appointment_data = data;
      state.schedule_appointment_is_loading = false;
    },
    [getScheduleAppointmentList.rejected]: (state, action) => {
      state.schedule_appointment_is_loading = false;
    },

    // get appointement date
    [getScheduleAppointmentDate.pending]: (state, action) => {
      state.schedule_appointment_date_is_loading = true;
    },
    [getScheduleAppointmentDate.fulfilled]: (state, action) => {
      let data = action.payload;
      state.schedule_appointment_date_data = data;
      state.schedule_appointment_date_is_loading = false;
    },
    [getScheduleAppointmentDate.rejected]: (state, action) => {
      state.schedule_appointment_date_is_loading = false;
    },

    // create appointment
    [createAppointment.pending]: (state, action) => {
      state.create_appointment_is_loading = true;
    },
    [createAppointment.fulfilled]: (state, action) => {
      let data = action.payload;
      state.create_appointment_is_loading = false;
    },
    [createAppointment.rejected]: (state, action) => {
      state.create_appointment_is_loading = false;
    },

    // create patient
    [createPatient.pending]: (state, action) => {
      state.create_patient_is_loading = true;
    },
    [createPatient.fulfilled]: (state, action) => {
      let data = action.payload;
      state.create_patient_is_loading = false;
    },
    [createPatient.rejected]: (state, action) => {
      state.create_patient_is_loading = false;
    },

    // update appointment
    [updateAppointment.pending]: (state, action) => {
      state.update_appointment_is_loading = true;
    },
    [updateAppointment.fulfilled]: (state, action) => {
      // let data = action.payload;
      state.update_appointment_is_loading = false;
    },
    [updateAppointment.rejected]: (state, action) => {
      state.update_appointment_is_loading = false;
    },

    // fetch patient docs
    [fetchPatientDocuments.pending]: (state, action) => {
      state.fetch_document_is_loading = true;
    },
    [fetchPatientDocuments.fulfilled]: (state, action) => {
      let data = action.payload;

      const groups = data.documents.reduce((groups, temp_docs) => {
        const docs = temp_docs.patient_documents;
        const date = docs.createdAt.split("T")[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(docs);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          documents: groups[date],
        };
      });

      state.fetch_document_data = groupArrays;
      state.fetch_request_document_data = data.documents_request;
      state.fetch_document_is_loading = false;
    },
    [fetchPatientDocuments.rejected]: (state, action) => {
      state.fetch_document_is_loading = false;
    },

    // remove request docs
    [removeRequestDocs.pending]: (state, action) => {
      state.remove_request_docs_is_loading = true;
    },
    [removeRequestDocs.fulfilled]: (state, action) => {
      state.remove_request_docs_is_loading = false;
    },
    [removeRequestDocs.rejected]: (state, action) => {
      state.remove_request_docs_is_loading = false;
    },

    // test list
    [hospitalTestList.pending]: (state, action) => {
      state.hospital_list_is_loading = true;
    },
    [hospitalTestList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.hospital_data = data;
      state.hospital_list_is_loading = false;
    },
    [hospitalTestList.rejected]: (state, action) => {
      state.hospital_list_is_loading = false;
    },

    // request docs
    [requestDocs.pending]: (state, action) => {
      state.request_is_loading = true;
    },
    [requestDocs.fulfilled]: (state, action) => {
      state.request_is_loading = false;
    },
    [requestDocs.rejected]: (state, action) => {
      state.request_is_loading = false;
    },

    // get patient notes
    [getPatientNotes.pending]: (state, action) => {
      state.get_notes_is_loading = true;
    },
    [getPatientNotes.fulfilled]: (state, action) => {
      let data = action.payload;

      const groups = data.rows.reduce((groups, notes) => {
        const date = notes.createdAt.split("T")[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(notes);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          notes: groups[date],
        };
      });
      state.patient_notes = groupArrays;
      state.patient_notes_count = data.count;
      state.get_notes_is_loading = false;
    },
    [getPatientNotes.rejected]: (state, action) => {
      state.get_notes_is_loading = false;
    },

    // add patient notes
    [addPatientNotes.pending]: (state, action) => {
      state.add_notes_is_loading = true;
    },
    [addPatientNotes.fulfilled]: (state, action) => {
      state.add_notes_is_loading = false;
    },
    [addPatientNotes.rejected]: (state, action) => {
      state.add_notes_is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_dark_mode } = scheduleSlice.actions;

export default scheduleSlice.reducer;
