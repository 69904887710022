import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../pages/Login.js";
import DoctorView from "../pages/doctor";
import SupportView from "../pages/support";
import { isAuth } from "../helpers/cookies.js";
import CometChat from "../cometchat/CometChat";
import { cometChatConfig } from "../helpers/constants.js";

const RoutesHome = () => {
  const uid = isAuth().uid;

  const loginCometChatUser = () => {
    const authKey = cometChatConfig.authKey;
    CometChat.login(uid, authKey).then(
      (user) => {
        console.log("Login Successful:", { user });
      },
      (error) => {
        console.log("Login failed with exception:", { error });
      }
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        {isAuth().role_name === "DOCTOR" || isAuth().role_name === "NURSE"
          ? (CometChat(),
            loginCometChatUser(),
            (<Route path="doctor/*" element={<DoctorView />} />))
          : null}
        {isAuth().role_name === "SUPPORT" ? (
          <Route path="support/*" element={<SupportView />} />
        ) : null}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
};

export default RoutesHome;
