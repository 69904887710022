import TextField from "@mui/material/TextField";
import React from "react";

const Input = (props) => {
  const {
    name,
    id,
    label,
    value,
    placeHolder,
    variant,
    onChange,
    error = null,
    ...other
  } = props;
  return (
    <TextField
      variant={variant || "outlined"}
      label={label}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
};

export default Input;
