// export const baseUrl = "http://localhost:3500";
const NODE_ENV = "production";

export const baseUrl =
  NODE_ENV === "development"
    ? "https://api-es.hipaasafe.app"
    : "https://api-es.hipaasafe.app";

export const cometChatConfig =
  NODE_ENV === "development"
    ? {
        appID: "209108fab026373a",
        appRegion: "us",
        authKey: "89ffb6993b8b745eb3a5ff1f7f0134b278d8e63d",
        widgetID: "373fee02-f452-4e38-8782-f828bcb00be9",
        defaultType: "user",
        docked: "true",
      }
    : {
        appID: "194597c4e37ea49f",
        appRegion: "us",
        authKey: "2f1cd984d0e65bce1b5fc511200af3b202567656",
        widgetID: "373fee02-f452-4e38-8782-f828bcb00be9",
        defaultType: "user",
        docked: "true",
      };

// API's
export const loginSendOTP = "/user/login/send-otp";
export const loginVerifyOTP = "/user/login/validate-otp";
export const updatePlayerIDAPI = "/user/patient/login/update-player_id";

export const doctorKPIDashboard = "/query/kpi-cards/fetch/dashboard";

export const patientList = "/query/appointments/fetch/by-date";
export const appointmentHistory = "/query/appointments/fetch/by-date-range";
export const futureAppointmentDateCount =
  "/query/appointments/fetch/week-count";
export const myTeamDoctorList = "/query/doctors/my-team";
export const myTeamNurseList = "/query/nurse/my-team";
export const patientsList = "/query/doctors/fetch/my-patients";
export const createAppointmentUrl = "/appointment/patient/book";
export const createPatientUrl = "/user/patient/register-by-doctor-nurse";
export const updateAppointmentUrl = "/appointment/doctor-nurse/update";

export const fetchPatientDocumentsUrl = "/documents/user/test-reports/fetch";
export const supportDashboardKpi = "/query/kpi-cards/fetch/organization";
export const removeRequestDocsUrl = "/documents/remove-request-from-patient";
export const requestDocsUrl = "/documents/request-from-patient";

export const hospitalTestListUrl = "/static/hospital-reports/list";

export const getPatientNotesUrl = "/notes/get";
export const addPatientNotesUrl = "/notes/add";
export const getPatientRecordsUrl = "/user/patient/list";

export const addDoctorsUrl = "/user/doctor/register";
export const updateDoctorsUrl = "/user/doctor/update-profile";
export const addNurseUrl = "/user/nurse/register";
export const updateNurseUrl = "/user/nurse/update-profile";

export const getUserDetailsUrl = "/user/profile/by-id";

export const getSpecialityListUrl = "/static/speciality/list";

export const required = (value) => (value ? undefined : "Required");

// cometchat config

// export const cometChatConfig = {
//   appID: "194597c4e37ea49f",
//   appRegion: "us",
//   authKey: "2f1cd984d0e65bce1b5fc511200af3b202567656",
//   widgetID: "373fee02-f452-4e38-8782-f828bcb00be9",
//   defaultType: "user",
//   docked: "true",
// };

//s3 bucket base url

export const S3BaseUrl =
  "https://akbh.s3.ap-south-1.amazonaws.com/hs/user/reports/";
