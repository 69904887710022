import React from "react";
import BaseLayout from "../../layout/BaseLayout";
import SupportRoutes from "../../routes/Support";
import HomeIcon from "@mui/icons-material/Home";
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

const doctorModules = [
  {
    id: "dashboard",
    title: "Dashboard",
    logo: <HomeIcon />,
    link: "/support/dashboard",
  },
  {
    id: "add_doctors",
    title: "Add Doctors / Nurses",
    logo: <LocalHospitalOutlinedIcon />,
    link: "/support/add-doctors",
  },
  // {
  //   id: "add_nurses",
  //   title: "Add Nurses",
  //   logo: <HourglassEmptyOutlinedIcon />,
  //   link: "/support/add-nurses",
  // },
];

const SupportContainer = () => {
  return (
    <div>
      <BaseLayout modules={doctorModules} ChildComponent={SupportRoutes} />
    </div>
  );
};

export default SupportContainer;
