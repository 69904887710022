import React from 'react';
import Table from "../../../components/common/Table";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MuiButton from '../../../components/common/Button';

const NurseList = (props) => {
    let { tableDetails,loading } = props;
    const columns = [
        {
            title: 'Sr.No.',
        },
        {
            title: 'Nurse Name',
        },
        {
            title: 'Email',
        },
        {
            title: 'Phone',
        },
        {
            title: 'City',
        },
        {
            title: 'Action',
        },
    ]

    const renderTableData = tableDetails.length ? tableDetails.map((row, index) => (
        <TableRow
            key={index}
            sx={{ 'td, th': { border: 0, padding: "10px" } }}
        >
            <TableCell align="center" component="th" scope="row">
                {index + 1}
            </TableCell>
            <TableCell align="center">{row.FirstName + " " + row.LastName}</TableCell>
            <TableCell align="center">{row.Email}</TableCell>
            <TableCell align="center">{row.Phone}</TableCell>
            <TableCell align="center">{row.City}</TableCell>
            <TableCell align="center">
                <MuiButton onClick={() => props.editNurse(row.ProviderId)} variant="outlined" sx={{ borderRadius: "50px" }}>Edit </MuiButton>
            </TableCell>
        </TableRow>
    )) : (
        <TableRow sx={{ 'td, th': { border: 0, padding: "10px" } }}>
            <TableCell align="center">Data not found</TableCell>
        </TableRow>
    )

    return (
        <Table columns={columns} tableData={renderTableData} loading={loading} />
    )
}

export default NurseList