import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_dark_mode } from "../redux/features/feedback.js";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { setDoctorId } from "../redux/doctor/dashboard.js";
import { isAuth, signout } from "../helpers/cookies.js";
import SyncEagle from "../components/common/SyncEagle.js";

const pages = ["Products", "Pricing", "Blog"];
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function BaseLayout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path_url = window.location.pathname;
  const { modules, ChildComponent } = props;
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { doctor_id } = useSelector((state) => state.doctor);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signout(() => navigate(0));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        elevation={0}
        open={true}
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          zIndex: 1200,
        }}
      >
        <Toolbar sx={isAuth().role_name === "NURSE" ? { width: "100%" } : null}>
          <SyncEagle />
          {isAuth().role_name === "NURSE" ? (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <FormControl>
                <InputLabel id="doctor-id-select-label">Doctor</InputLabel>
                <Select
                  labelId="doctor-id-select-label"
                  id="doctor-id-select"
                  size="small"
                  value={doctor_id}
                  label="Doctor"
                  onChange={(val) => dispatch(setDoctorId(val.target.value))}
                >
                  {isAuth().doctors_mapped.map(function (dd) {
                    return <MenuItem value={dd.uid}>{dd.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}

          {auth && (
            <Box
              pr={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {isAuth().avatar ? (
                  <Avatar
                    alt={isAuth().FirstName}
                    src={isAuth().avatar}
                    sx={{ mr: 1, bgcolor: "grey" }}
                  />
                ) : (
                  <AccountCircle
                    sx={{ color: "#bdbdbd", width: "32px", height: "32px" }}
                  />
                )}
              </IconButton>
              <Typography variant="subtitle1" sx={{ color: "#1C1C1C", mr: 2 }}>
                {isAuth().FirstName}
              </Typography>
              <IconButton title="Log Out" size="large" onClick={handleSignOut}>
                <LogoutIcon
                  sx={{
                    color: "#1C1C1C",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={true}>
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/HipaasafeLogo.svg" alt="logo" />
        </DrawerHeader>
        <List>
          {modules.map((module, i) => {
            return (
              <Tooltip
                key={("module_", i)}
                placement="right"
                title={module.title}
              >
                <ListItem
                  button
                  key={module.id}
                  onClick={() => navigate(module.link)}
                  selected={path_url === module.link ? true : false}
                  sx={{ mx: 2, my: 0.5, width: "unset", borderRadius: "10px" }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "35px",
                      ...(path_url === module.link && { color: "#0098FF" }),
                    }}
                  >
                    {module.logo}
                  </ListItemIcon>
                  <ListItemText
                    primary={module.title}
                    sx={{
                      ...(path_url === module.link && { color: "#0098FF" }),
                    }}
                  />
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
        {/* <Divider /> */}
        {/* <List>
          <Tooltip placement="right" title="Dark Mode">
            <ListItem
              button
              key={"Dark Mode"}
              onClick={() => {
                dispatch(set_dark_mode());
              }}
              sx={{ mx: 2, my: 0.5, width: "unset", borderRadius: "10px" }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                {<Brightness4Icon />}
              </ListItemIcon>
              <ListItemText primary={"Dark Mode"} />
            </ListItem>
          </Tooltip>
        </List> */}
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: "#F8F8F8" }}
      >
        <DrawerHeader />
        <ChildComponent />
      </Box>
    </Box>
  );
}
