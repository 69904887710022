import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import InputAdornment from "@mui/material/InputAdornment";

import AccountCircle from "@mui/icons-material/AccountCircle";

import { isAuth } from "../../helpers/cookies";

const ProfileContainer = () => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={6}>
        <Paper elevation={0} sx={{ alignItems: "center" }}>
          <Stack
            container
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
            spacing={2}
            py={2}
          >
            {isAuth().avatar ? (
              <Avatar
                alt={isAuth().FirstName}
                src={isAuth().avatar}
                sx={{ bgcolor: "grey", width: "192px", height: "192px" }}
              />
            ) : (
              <AccountCircle
                sx={{ color: "#bdbdbd", width: "192px", height: "192px" }}
              />
            )}

            <TextField
              type="text"
              sx={{ width: "70%" }}
              variant="outlined"
              value={isAuth().FirstName + " " + isAuth().LastName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              name="name"
              disabled={true}
            />

            <TextField
              type="text"
              sx={{ width: "70%" }}
              variant="outlined"
              value={isAuth().Email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              name="email"
              disabled={true}
            />

            {isAuth().role_name === "DOCTOR" ? (
              <>
                {" "}
                <TextField
                  type="text"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  value={isAuth().City}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  name="speciality"
                  disabled={true}
                />
                <TextField
                  type="text"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  value={isAuth().mobile}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  name="experience"
                  disabled={true}
                />{" "}
              </>
            ) : null}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProfileContainer;
