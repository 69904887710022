import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const AuthAsidepanel = () => {
  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <img
        src="/images/Hipaasafe_Logo.svg"
        alt="logo"
        sx={{ width: "300px" }}
        width="250px"
      />
      <Typography
        variant="h5"
        mt={0.5}
        ml={-2}
        sx={{ fontWeight: "400", fontSize: "18px", color: "#E6F5FF" }}
      >
        Doctor Portal
      </Typography>
    </Grid>
  );
};

export default AuthAsidepanel;
