import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from "@mui/material/Paper";
import KpiCount from "../../components/support/dashboard/KpiCount";
import DoctorList from '../../components/support/dashboard/DoctorList';
import NurseList from '../../components/support/dashboard/NurseList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from "react-redux";
import { getSupportDashboardKpi, getMyTeamDoctorList, getMyTeamNurseList, getUserDetails } from "../../redux/support/dashboard";
import { useNavigate } from 'react-router-dom';
import { axios } from '../../helpers/Server';
import { getUserDetailsUrl } from '../../helpers/constants';

const DashboardContainer = () => {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();

  const { dashboard_kpi_count, user_details, kpi_is_loading, dashboard_doctor_list, doctor_list_is_loading, dashboard_nurse_list, nurse_list_is_loading } = useSelector((state) => state.supportDashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    if (value === '1') {
      dispatch(getMyTeamDoctorList());
    }
    else {
      dispatch(getMyTeamNurseList());
    }

  }, [dispatch, value]);

  useEffect(() => {
    dispatch(getSupportDashboardKpi());
  }, [dispatch])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const kpiDetails = [
    {
      img: "/images/support_doctor.svg",
      alt: "total doctors",
      count: dashboard_kpi_count?.total_doctors ? dashboard_kpi_count.total_doctors : 0,
      title: "Total Doctors",
    },
    {
      img: "/images/support_nurse.svg",
      alt: "total nurses",
      count: dashboard_kpi_count?.total_nurses ? dashboard_kpi_count.total_nurses : 0,
      title: "Total Nurses",
    }
  ];

  const tableDetails = value === "1" && dashboard_doctor_list?.rows ? dashboard_doctor_list.rows : value === "2" && dashboard_nurse_list?.rows ? dashboard_nurse_list.rows : []

  const renderPatientsCard = kpiDetails.map((kpiCount, index) => (
    <KpiCount kpiCount={kpiCount} key={index} loading={kpi_is_loading} />
  ));

  // useEffect(() => {
  //   if (user_details && user_details.role_name === 'NURSE') {
  //     navigate("/support/add-nurses", { state: { details: user_details } })
  //   } else if (user_details && user_details.role_name === 'DOCTOR') {
  //     navigate("/support/add-doctors", { state: { details: user_details } })
  //   }
  // }, [user_details, navigate])

  const editNurse = async (uid) => {
    const endpoint = `${getUserDetailsUrl}?user_id=${uid}`;
    const data = await axios.get(endpoint);
    if (data.success) {
      let doctorMap = [];
      data.data.doctors_mapped.map(function (dd) {
        return doctorMap.push(dd.uid);
      })

      let initialData = {
        name: data.data.name,
        email: data.data.email,
        country_code: data.data.country_code,
        number: data.data.number,
        location: data.data.metadata.location,
        experience: data.data.metadata.experience,
        doctor_ids: doctorMap
      };
      navigate("/support/add-nurses", { state: { uid: data.data.uid, user_details: initialData } })
    }
  }

  const editDoctors = async (uid) => {
    const endpoint = `${getUserDetailsUrl}?user_id=${uid}`;
    const data = await axios.get(endpoint);
    if (data.success) {
      let tagArray = [];
      data.data.doctor_details.tags.map(function (dd) {
        return tagArray.push(dd.speciality_id);
      })

      let initialData = {
        name: data.data.name,
        email: data.data.email,
        country_code: data.data.country_code,
        number: data.data.number,
        location: data.data.doctor_details.location,
        experience: data.data.doctor_details.experience,
        speciality_id: data.data.doctor_details.speciality.speciality_id,
        tags: tagArray
      };
      navigate("/support/add-doctors", { state: { uid: data.data.uid, user_details: initialData } })
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "20px" }}>
            Dashboard
          </Typography>
        </Box>
      </Grid>
      <Grid container item spacing={1}>
        {renderPatientsCard}
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, color: "#071B2A", fontWeight: "400" }} elevation={0}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Doctors List" value="1" />
                  <Tab label="Nurses List" value="2" />
                </Tabs>
              </Box>
              <TabPanel value="1" sx={{ px: 0 }}>
                <DoctorList navigate={navigate} editDoctors={(uid) => editDoctors(uid)} tableDetails={tableDetails} loading={doctor_list_is_loading} />
              </TabPanel>
              <TabPanel value="2">
                <NurseList navigate={navigate} editNurse={(uid) => editNurse(uid)} tableDetails={tableDetails} loading={nurse_list_is_loading} />
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default DashboardContainer;
