import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import SchedulePatientAppointementCard from "../../components/doctor/schedule/SchedulePatientAppointementCard";
import Drawer from "@mui/material/Drawer";
import Form from "../../components/common/Form";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Input from "../../components/common/Input";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  getScheduleAppointmentList,
  getScheduleAppointmentDate,
  createAppointment,
  updateAppointment,
  createPatient,
} from "../../redux/doctor/schedule";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import { useForm } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import FuturePatientList from "../../components/doctor/schedule/FuturePatientList";
import ViewPatients from "./ViewPatients";
import countries from "../../helpers/countries";
import CountriesSelect from "../../components/common/CountriesSelect";
import { getPatientRecordsUrl } from "../../helpers/constants";
import { axios } from "../../helpers/Server";

const ScheduleContainer = () => {
  const [value, setValue] = useState(0);
  const [appointment, setAppointment] = useState(false);
  const [date, setDate] = React.useState(new Date());
  const [time, setTime] = React.useState(new Date());
  const { doctor_id } = useSelector((state) => state.doctor);

  const [patientData, setPatientData] = useState(null);

  const [open, setOpen] = useState(false);
  const [patientRecords, setPatientRecords] = useState([]);
  const [patientSearch, setPatientSearch] = useState("");
  const [patientId, setPatientId] = useState();
  const [patientAddOpen, setPatientAddOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (patientSearch !== "") {
      async function defaultFunc() {
        const endpoint = `${getPatientRecordsUrl}?keyword=${patientSearch}`;
        const res = await axios.get(endpoint);

        if (res.success) {
          setPatientRecords(res.data);
        }
      }

      defaultFunc();
    }
  }, [patientSearch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
  } = useForm();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = () => {
    reset({});
    setAppointment(!appointment);
  };

  const togglePatientDrawer = () => {
    reset({});
    setPatientAddOpen(!patientAddOpen);
  };

  const {
    schedule_appointment_date_data,
    schedule_appointment_data,
    schedule_appointment_is_loading,
    schedule_appointment_date_is_loading,
    create_appointment_is_loading,
    create_patient_is_loading,
  } = useSelector((state) => state.schedule);

  useEffect(() => {
    dispatch(getScheduleAppointmentDate({ doctor_id: doctor_id }));
  }, [dispatch, doctor_id]);

  // function getAppointmentList(value, schedule_appointment_date_data, dispatch, doctor_id) {
  //   const selectedDate = schedule_appointment_date_data[0]
  //     ? schedule_appointment_date_data[value].date
  //     : false;

  //   if (selectedDate)
  //     dispatch(
  //       getScheduleAppointmentList({
  //         doctor_id: doctor_id,
  //         appointment_date: selectedDate,
  //       })
  //     );
  // }

  useEffect(() => {
    const selectedDate = schedule_appointment_date_data[0]
      ? schedule_appointment_date_data[value].date
      : false;
    if (selectedDate)
      dispatch(
        getScheduleAppointmentList({
          doctor_id: doctor_id,
          appointment_date: selectedDate,
        })
      );
  }, [value, schedule_appointment_date_data, dispatch]);

  const futurePatientsAppointmentDetails = [
    {
      img: "/images/total_appointments.svg",
      alt: "Total Appointments",
      count: schedule_appointment_data?.kpi
        ? schedule_appointment_data.kpi.total_appointments
        : 0,
      title: "Total Appointments",
    },
    {
      img: "/images/waiting_patient.svg",
      alt: "Confirmaton Pending",
      count: schedule_appointment_data?.kpi
        ? schedule_appointment_data.kpi.confirmation_pending
        : 0,
      title: "Confirmaton Pending",
    },
    {
      img: "/images/completed_patient.svg",
      alt: "Confirmed",
      count: schedule_appointment_data?.kpi
        ? schedule_appointment_data.kpi.confirmed
        : 0,
      title: "Confirmed",
    },
    {
      img: "/images/cancelled.svg",
      alt: "Cancelled",
      count: schedule_appointment_data?.kpi
        ? schedule_appointment_data.kpi.cancelled
        : 0,
      title: "Cancelled",
    },
  ];

  const renderAppointmentDetails = futurePatientsAppointmentDetails.map(
    (futureAppointmentList, i) => (
      <SchedulePatientAppointementCard
        futureAppointmentList={futureAppointmentList}
        loading={
          schedule_appointment_is_loading ||
          schedule_appointment_date_is_loading
        }
        key={i}
      />
    )
  );

  const futureCardsSkeleton = () => {
    let futureCardChildComponent = [];
    for (let i = 0; i < 7; i++) {
      futureCardChildComponent.push(
        <Skeleton
          key={i}
          variant="text"
          width={80}
          height={45}
          sx={{ ml: 2 }}
        />
      );
    }
    return <Stack direction="row">{futureCardChildComponent}</Stack>;
  };

  const onSubmit = (data) => {
    let datess = new Date(
      moment(date).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm:ss")
    ).toISOString();

    let payload = {
      StartTime: datess,
      ProviderId: doctor_id,
      PatientId: patientId,
    };

    if (data.AppointmentId) {
      updateAppointmentAction({ ...payload, ...data }, "RESCHEDULE");
    } else {
      dispatch(createAppointment(payload));
    }
    if (!create_appointment_is_loading) toggleDrawer();
  };

  const updateAppointmentAction = (data, action) => {
    let payload = {
      update: {
        AppointmentId: data.AppointmentId,
        appointment_date: data.appointment_date || date,
        type: action,
      },
      refresh: {
        appointment_date: data.appointment_date || date,
        doctor_id: doctor_id,
      },
    };

    dispatch(updateAppointment(payload));
  };

  const rescheduleAppointment = (data) => {
    let payload = {
      name: data.appointment_patient.FirstName,
      AppointmentId: data.AppointmentId,
    };

    setDate(new Date(data.StartTime));
    setTime(new Date(data.StartTime));
    reset(payload);
    setAppointment(!appointment);
  };

  const viewPatient = (data) => {
    setPatientData(data);
  };

  const onPatientSubmit = (data) => {
    dispatch(createPatient(data));
    if (!create_patient_is_loading) togglePatientDrawer();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="h5" sx={{ fontSize: "20px" }}>
              Appointments
            </Typography>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="primary"
              onClick={toggleDrawer}
            >
              Create an Appointment
            </Button>
            <Drawer
              anchor="right"
              open={appointment}
              onClose={toggleDrawer}
              sx={(theme) => ({
                "& .MuiPaper-root": {
                  padding: theme.spacing(3.5),
                  width: 450,
                },
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Create An Appointment</Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={toggleDrawer}
                >
                  <img
                    src="/images/cancel.svg"
                    width={35}
                    height={35}
                    alt="close"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
              </Box>
              <Form
                sx={{ position: "relative" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                {getValues("AppointmentId") ? (
                  <input
                    type="hidden"
                    name="AppointmentId"
                    {...register("AppointmentId")}
                  />
                ) : null}

                <Grid container>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%", mt: 3 }}>
                      <Typography
                        align="left"
                        variant="subtitle2"
                        sx={{ color: "#5F5F5F", mb: 1 }}
                      >
                        Appointment Details
                      </Typography>
                      {/* <TextField
                        InputLabelProps={{
                          style: { color: "#B2B9CC" },
                        }}
                        type="text"
                        fullWidth
                        label="Patient Name"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <PersonOutlinedIcon />
                            </InputAdornment>
                          ),
                          readOnly: getValues("AppointmentId") ? true : false,
                        }}
                        name="name"
                        {...register("name", { required: true })}
                        {...(errors.name && {
                          error: true,
                          helperText: "This field is required",
                        })}
                      /> */}
                      <Autocomplete
                        open={open}
                        onOpen={() => {
                          if (patientSearch) {
                            setOpen(true);
                          }
                        }}
                        onClose={() => setOpen(false)}
                        inputValue={patientSearch}
                        onInputChange={(e, value, reason) => {
                          setPatientSearch(value);

                          if (!value) {
                            setOpen(false);
                          }
                        }}
                        options={patientRecords}
                        getOptionLabel={(option) =>
                          option.FirstName + " " + option.LastName
                        }
                        onChange={(event, newValue) => {
                          setPatientId(newValue?.PatientId);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Search Patient" />
                        )}
                      />
                    </FormControl>

                    {/* <FormControl sx={{ width: "100%", mt: 1 }}>
                      <TextField
                        InputLabelProps={{
                          style: { color: "#B2B9CC" },
                        }}
                        fullWidth
                        label="Email"
                        variant="outlined"
                        type="email"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <EmailOutlinedIcon />
                            </InputAdornment>
                          ),
                          readOnly: getValues("AppointmentId") ? true : false,
                        }}
                        name="email"
                        {...register("email", { required: true })}
                        {...(errors.email && {
                          error: true,
                          helperText: "This field is required",
                        })}
                      />
                    </FormControl> */}

                    {/* <FormControl
                      sx={{
                        width: "100%",
                        my: 1,
                        mr: 2,
                        backgroundColor: "#fff",
                      }}
                    >
                      <CountriesSelect
                        name={"country_code"}
                        label={"Country Code"}
                        control={control}
                        values={["aaa", "bbb", "ccc"]}
                      />
                    </FormControl> */}

                    {/* <FormControl
                      sx={{
                        width: "100%",
                        my: 1,
                        mr: 2,
                        backgroundColor: "#fff",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Mobile Number"
                        variant="outlined"
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <PhoneAndroidIcon />
                            </InputAdornment>
                          ),
                        }}
                        {...register("number", {
                          required: "Mobile number is required",
                        })}
                        {...(errors.number && {
                          error: true,
                          helperText: errors.number.message,
                        })}
                      />
                    </FormControl> */}

                    <FormControl sx={{ width: "100%", mt: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date"
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          value={date}
                          components={{
                            OpenPickerIcon: (props) => (
                              <DateRangeOutlinedIcon
                                {...props}
                                sx={{ color: "#B2B9CC" }}
                              />
                            ),
                          }}
                          name="appointment_date"
                          renderInput={(params) => <Input {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ width: "100%", mt: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time"
                          onChange={(newValue) => {
                            setTime(newValue);
                          }}
                          value={time}
                          components={{
                            OpenPickerIcon: (props) => (
                              <AccessTimeOutlinedIcon
                                {...props}
                                sx={{ color: "#B2B9CC" }}
                              />
                            ),
                          }}
                          name="appointment_time"
                          renderInput={(params) => <Input {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ px: 3, position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        fullWidth
                        type={"submit"}
                        sx={{ position: "absolute", bottom: "-120px", mx: 4 }}
                      >
                        {getValues("AppointmentId")
                          ? "Reschedule Appointment"
                          : "Create an Appointment"}
                      </Button>
                      <Button
                        variant="text"
                        disableElevation
                        size="large"
                        fullWidth
                        type={"button"}
                        sx={{ position: "absolute", bottom: "-170px", mx: 4 }}
                        onClick={togglePatientDrawer}
                      >
                        Add Patient
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </Drawer>

            <Drawer
              anchor="right"
              open={patientAddOpen}
              onClose={togglePatientDrawer}
              sx={(theme) => ({
                "& .MuiPaper-root": {
                  padding: theme.spacing(3.5),
                  width: 400,
                },
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Create An Patient</Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={togglePatientDrawer}
                >
                  <img
                    src="/images/cancel.svg"
                    width={35}
                    height={35}
                    alt="close"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
              </Box>
              <Form
                sx={{ position: "relative" }}
                onSubmit={handleSubmit2(onPatientSubmit)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%", mt: 3 }}>
                      <Typography
                        align="left"
                        variant="subtitle2"
                        sx={{ color: "#5F5F5F", mb: 1 }}
                      >
                        Patient Details
                      </Typography>
                      <TextField
                        InputLabelProps={{
                          style: { color: "#B2B9CC" },
                        }}
                        type="text"
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <PersonOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        name="FirstName"
                        {...register2("FirstName", { required: true })}
                        {...(errors2.FirstName && {
                          error: true,
                          helperText: "This field is required",
                        })}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%", mt: 3 }}>
                      <TextField
                        InputLabelProps={{
                          style: { color: "#B2B9CC" },
                        }}
                        type="text"
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <PersonOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        name="LastName"
                        {...register2("LastName", { required: true })}
                        {...(errors2.LastName && {
                          error: true,
                          helperText: "This field is required",
                        })}
                      />
                    </FormControl>

                    <FormControl sx={{ width: "100%", mt: 1 }}>
                      <TextField
                        InputLabelProps={{
                          style: { color: "#B2B9CC" },
                        }}
                        fullWidth
                        label="Email"
                        variant="outlined"
                        type="email"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <EmailOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        name="EmailAddress"
                        {...register2("EmailAddress", { required: true })}
                        {...(errors2.EmailAddress && {
                          error: true,
                          helperText: "This field is required",
                        })}
                      />
                    </FormControl>

                    <FormControl
                      sx={{
                        width: "100%",
                        my: 1,
                        mr: 2,
                        backgroundColor: "#fff",
                      }}
                    >
                      <CountriesSelect
                        name={"country_code"}
                        label={"Country Code"}
                        control={control2}
                        values={["aaa", "bbb", "ccc"]}
                      />
                    </FormControl>

                    <FormControl
                      sx={{
                        width: "100%",
                        my: 1,
                        mr: 2,
                        backgroundColor: "#fff",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Mobile Number"
                        variant="outlined"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <PhoneAndroidIcon />
                            </InputAdornment>
                          ),
                        }}
                        {...register2("number", {
                          required: "Mobile number is required",
                        })}
                        {...(errors2.number && {
                          error: true,
                          helperText: errors.number.message,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ px: 3, position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        fullWidth
                        type={"submit"}
                        sx={{ position: "absolute", bottom: "-120px", mx: 4 }}
                      >
                        Add Patient
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </Drawer>
          </Box>
        </Grid>

        <Grid container item spacing={1}>
          <TabContext value={value.toString()}>
            <Grid item xs={12}>
              <Paper elevation={0}>
                <Box sx={{ maxWidth: { lg: 1000 } }}>
                  {schedule_appointment_date_is_loading ? (
                    futureCardsSkeleton()
                  ) : schedule_appointment_date_data.length ? (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      {schedule_appointment_date_data.map(
                        (appointmentDate, index) => {
                          let dateObj = new Date(
                            appointmentDate.date.split("T")[0]
                          );
                          const formatAppointmentDate = `${dateObj.getDate()} ${moment(
                            dateObj
                          ).format("MMM")} (${appointmentDate.count}) `;
                          return (
                            <Tab
                              label={formatAppointmentDate}
                              value={index}
                              key={index}
                            />
                          );
                        }
                      )}
                    </Tabs>
                  ) : null}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <TabPanel value={value.toString()} sx={{ p: 0 }}>
                <Grid container item spacing={1}>
                  {renderAppointmentDetails}
                  <Grid item xs={12}>
                    <FuturePatientList
                      is_loading={
                        schedule_appointment_is_loading ||
                        schedule_appointment_date_is_loading
                      }
                      rows={schedule_appointment_data.rows}
                      count={schedule_appointment_data.count}
                      updateAppointmentAction={(d, act) =>
                        updateAppointmentAction(d, act)
                      }
                      rescheduleAppointment={(data) =>
                        rescheduleAppointment(data)
                      }
                      viewPatient={(data) => viewPatient(data)}
                    />
                    {/* <ViewPatient patientData={patientData} setPatientData={() => setPatientData(null)} /> */}
                    <ViewPatients
                      patientData={patientData}
                      setPatientData={() => setPatientData(null)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              {/* <TabPanel value="2">Item Two</TabPanel>
              <TabPanel value="3">Item Three</TabPanel> */}
            </Grid>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleContainer;
