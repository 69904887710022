import React from "react";
import Routes from "./routes";

// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_dark_mode } from "./redux/features/feedback.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import SnackBarContainer from "./components/common/SnackBar";

function App() {
  const dispatch = useDispatch();
  // const { dark_mode } = useSelector((state) => state.feedback);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  React.useEffect(() => {
    dispatch(set_dark_mode(prefersDarkMode ? "dark" : "light"));
  }, [dispatch, prefersDarkMode]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode: dark_mode ? dark_mode : prefersDarkMode ? "dark" : "light",
          mode: "light",
        },
        primary: {
          main: "#0098FF",
          dark: "#007ACC",
        },
      }),
    // [dark_mode, prefersDarkMode]
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackBarContainer />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
