import moment from "moment";
import Typography from "@mui/material/Typography";

const DashboardHeader = (props) => {
  const { title, time } = props;

  return (
    <>
      <Typography variant="h5" sx={{ fontSize: "20px" }}>
        {title}
      </Typography>
      <Typography variant="caption" pl={2}>
        {time}
      </Typography>
    </>
  );
};

export default DashboardHeader;
