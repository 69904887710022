import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormHelperText,
  Paper,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import ListItemButton from "@mui/material/ListItemButton";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientNotes,
  fetchPatientDocuments,
  getPatientNotes,
  hospitalTestList,
  removeRequestDocs,
  requestDocs,
} from "../../redux/doctor/schedule";
import { useForm } from "react-hook-form";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Form from "../../components/common/Form";
import moment from "moment";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { S3BaseUrl } from "../../helpers/constants";
import MuiButton from "../../components/common/Button";
import { set_chat_window_ids } from "../../redux/features/feedback";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewPatients = (props) => {
  const [open, setOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({
    fileName: "",
    fileUrl: "",
    fileType: "",
  });

  const handleClickOpen = (e, name, url, type) => {
    setOpen(true);
    setDocumentDetails({ fileName: name, fileUrl: url, fileType: type });
  };

  const handleClose = () => {
    setOpen(false);
    setDocumentDetails({ fileName: "", fileUrl: "", fileType: "" });
  };

  const dispatch = useDispatch();

  const {
    fetch_document_data,
    fetch_request_document_data,
    fetch_document_is_loading,
  } = useSelector((state) => state.schedule);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.log(props.patientData, "props.patientData");
    if (props.patientData && value === "1") {
      dispatch(fetchPatientDocuments(props.patientData?.guid));
    }
  }, [dispatch, props.patientData, value]);

  const deleteDocs = (id) => {
    dispatch(
      removeRequestDocs({
        id: id,
        patient_id: props.patientData?.PatientId,
      })
    );
  };

  const Age = moment().diff(props.patientData?.appointment_patient?.BirthDate, "years");
  const guid = props.patientData?.guid;

  const openChat = (id, type) => {
    try {
      if (type === "MYTEAM") {
        dispatch(set_chat_window_ids({ uid: id }));
      } else if (type === "PATIENT") {
        dispatch(set_chat_window_ids({ guid: id }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={props.patientData ? true : false}
      onClose={() => props.setPatientData(null)}
      sx={(theme) => ({
        "& .MuiPaper-root": {
          padding: theme.spacing(2.5),
          width: 500,
        },
      })}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Patient Details</Typography>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => props.setPatientData(null)}
        >
          <img
            src="/images/cancel.svg"
            width={35}
            height={35}
            alt="close"
            sx={{ cursor: "pointer" }}
          />
        </IconButton>
      </Stack>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
          border: "1px solid #E0E3E9",
          borderRadius: "10px",
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <img src="/images/person.svg" alt="person svg" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  {props.patientData?.appointment_patient?.FirstName + " "+props.patientData?.appointment_patient?.LastName}
                </Typography>
                <MuiButton
                  disableElevation
                  variant="outlined"
                  sx={{ borderRadius: "50px" }}
                  onClick={() => openChat(guid, "PATIENT")}
                >
                  Chat{" "}
                </MuiButton>
              </Stack>
            }
            secondary={`Age: ${Age} `}
          />
        </ListItem>
      </List>

      <Box sx={{ width: "100%", typography: "body1", mt: 2 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="lab API tabs example"
            >
              <Tab label="Documents" value="1" />
              <Tab label="Request Documents" value="2" />
              <Tab label="Notes" value="3" />
            </Tabs>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            <>
              {fetch_document_data.length ? (
                fetch_document_data.map((data, index) => {
                  return (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        {moment(data.date).format("MMM DD, YYYY")}
                      </Typography>
                      {data.documents.map((document, index) => {
                        const docFile = document?.document_file.split(".")[1];
                        const name = document.document_name;
                        const url = document.document_file;
                        return (
                          <List
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                              p: 0,
                            }}
                          >
                            <ListItem sx={{ px: 0, pb: 1.5 }}>
                              <ListItemButton
                                onClick={(e) =>
                                  handleClickOpen(e, name, url, docFile)
                                }
                              >
                                <ListItemAvatar>
                                  <Avatar>
                                    <img
                                      src={
                                        docFile === "pdf"
                                          ? "/images/pdf-icon.svg"
                                          : "/images/image.svg"
                                      }
                                      alt="patient docs"
                                    />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={document.document_name}
                                  sx={{ pl: 1.5 }}
                                />
                              </ListItemButton>
                            </ListItem>
                            <Divider component="li" variant="fullWidth" />
                          </List>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <Typography align="center" sx={{ mt: 5 }}>
                  Document Not Found
                </Typography>
              )}

              {fetch_request_document_data.length ? (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Requested Documents
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {fetch_request_document_data.map(
                      (request_document, index) => {
                        return (
                          <ListItem
                            key={index}
                            sx={{
                              px: 1.5,
                              pb: 1.5,
                              mb: 1,
                              backgroundColor: "rgba(25, 118, 210, 0.08)",
                              borderRadius: "10px",
                            }}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteDocs(request_document.id)}
                              >
                                <img
                                  src="/images/cancel2.svg"
                                  alt="cancel svg"
                                />
                              </IconButton>
                            }
                          >
                            <ListItemIcon sx={{ minWidth: "35px" }}>
                              <img src="/images/note.svg" alt="note svg" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ p: 0.5 }}
                              primary={
                                <Typography
                                  variant="subtitle2"
                                  sx={{ color: "#0098FF" }}
                                >
                                  {request_document.hospital_tests?.title}
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </>
              ) : null}
            </>
          </TabPanel>
          <TabPanel value="2">
            <RequestDocument
              setValue={() => setValue(0)}
              patient_id={props.patientData?.PatientId}
              guid={props.patientData?.guid}
            />
          </TabPanel>
          <TabPanel value="3" sx={{ py: 1.5, px: 1 }}>
            <PatientNotes
              patient_id={props.patientData?.PatientId}
              guid={props.patientData?.guid}
            />
          </TabPanel>
        </TabContext>
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {documentDetails?.fileName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100vh" }}
        >
          {<iframe src={S3BaseUrl + documentDetails?.fileUrl} title="documents details" width="100%" />}
        </Stack>
      </Dialog>
    </Drawer>
  );
};

const RequestDocument = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { doctor_id } = useSelector((state) => state.doctor);
  const { hospital_data, hospital_list_is_loading, request_is_loading } =
    useSelector((state) => state.schedule);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    let payload = {
      guid: props.guid,
      ...data,
    };

    dispatch(requestDocs(payload));
    if (!request_is_loading) props.setValue(0);
  };

  useEffect(() => {
    dispatch(hospitalTestList(doctor_id));
  }, [dispatch, doctor_id]);

  return (
    <Form sx={{ position: "relative" }} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} sx={{ height: "80%" }}>
        <Box xs={12} sx={{ paddingBottom: 1 }}>
          <Typography variant="subtitle2" sx={{ color: "#808591" }}>
            Tests
          </Typography>
        </Box>
        <Box xs={12} sx={{ paddingBottom: 1 }}>
          {hospital_data &&
            hospital_data.map(function (dd) {
              return (
                <FormControlLabel
                  key={dd.id}
                  value={dd.id}
                  control={<Checkbox />}
                  label={dd.title}
                  name="hospital_reports_id"
                  labelPlacement="end"
                  {...register("hospital_reports_id", { required: true })}
                />
              );
            })}
          {errors.hospital_reports_id ? (
            <FormHelperText error>Select atleast one test</FormHelperText>
          ) : null}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ position: "absolute", bottom: "2rem", width: "80%" }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ px: 3, position: "relative" }}
        >
          <Button variant="contained" size="large" fullWidth type={"submit"}>
            Send Request
          </Button>
        </Stack>
      </Grid>
    </Form>
  );
};

const PatientNotes = (props) => {
  const { doctor_id } = useSelector((state) => state.doctor);
  const dispatch = useDispatch();
  const {
    patient_notes,
    patient_notes_count,
    get_notes_is_loading,
    add_notes_is_loading,
  } = useSelector((state) => state.schedule);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [notesData, setNotesData] = useState(null);
  const [counts, setCount] = useState(0);

  const [listConf, setListConf] = useState({
    page: 1,
    limit: 10,
    DoctorId: doctor_id,
    PatientId: props.patient_id,
    guid: props.guid,
  });

  useEffect(() => {
    dispatch(getPatientNotes(listConf));
  }, [dispatch, listConf]);

  const onSubmit = (data) => {
    let payload = {
      add: {
        DoctorId: doctor_id,
        PatientId: props.patient_id,
        guid: props.guid,
        NoteText: data.notes,
      },
      refresh: listConf,
    };

    dispatch(addPatientNotes(payload));
  };

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        <Typography sx={{ mr: 0.5 }}>
          <img src="/images/note.svg" />
        </Typography>{" "}
        <Typography variant="subtitle1">My Notes</Typography>
      </Stack>
      <Box
        sx={
          patient_notes.length > 0 ? { height: 260, overflowY: "scroll" } : {}
        }
      >
        {patient_notes.length ? (
          patient_notes.map((data, index) => (
            <React.Fragment key={index}>
              <Typography variant="subtitle2" sx={{ my: 1 }}>
                {moment(data.date).format("MMM DD, YYYY")}
              </Typography>
              {data.notes.map((note, i) => (
                <React.Fragment key={i + 1}>
                  <Stack
                    direction="column"
                    sx={{
                      backgroundColor: "rgba(25, 118, 210, 0.08)",
                      p: 2,
                      borderRadius: "10px",
                      my: 1,
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Typography variant="subtitle2">
                        {note.doctor_details?.FirstName+" "+note.doctor_details?.LastName}
                      </Typography>
                      <Typography variant="subtitle2">
                        {moment(note.createdAt).format("h:mm a")}
                      </Typography>
                    </Stack>
                    <Typography variant="subtitle2">{note.NoteText}</Typography>
                  </Stack>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))
        ) : (
          <Typography align="center">No note found</Typography>
        )}
      </Box>
      <Grid sx={{ flexGrow: 1 }} container>
        <Form sx={{ position: "relative" }} onSubmit={handleSubmit(onSubmit)}>
          <Grid
            item
            xs={12}
            sx={{ position: "absolute", bottom: "2px", width: "90%" }}
          >
            <Box xs={12} sx={{ paddingBottom: 1 }}>
              <TextField
                id="outlined-multiline-static"
                label="Add a note"
                multiline
                rows={2}
                fullWidth
                placeholder="Add a note"
                name="notes"
                {...register("notes", { required: true })}
                error={errors.notes ? true : false}
              />
              {errors.notes ? (
                <FormHelperText error>Please add some text</FormHelperText>
              ) : null}
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ position: "relative" }}
            >
              <Button
                variant="contained"
                size="large"
                fullWidth
                type={"submit"}
              >
                {add_notes_is_loading ? (
                  <CircularProgress sx={{ color: "#fff" }} />
                ) : (
                  "Save"
                )}
              </Button>
            </Stack>
          </Grid>
        </Form>
      </Grid>
    </>
  );
};

export default ViewPatients;
