import React from 'react';
import Table from "../../../components/common/Table";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MuiButton from '../../../components/common/Button';

const Patients = (props) => {
    const { patientsDetails, openChat, loading } = props;
    const columns = [
        {
            title: 'Sr.No.',
        },
        {
            title: 'Patient Name',
        },
        {
            title: 'Phone',
        },
        {
            title: 'Chat',
        },
    ]

    const handleOpenChat = (uid) => {
        openChat(uid, "PATIENT");
    }

    const renderTableData = patientsDetails.length ? patientsDetails.map((row, index) => {
        const { guid } = row;
        return (
            <TableRow
                key={index}
                sx={{ 'td, th': { border: 0, padding: "10px" } }}
            >
                <TableCell align="center" component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">{row.list_patient_details.FirstName + " " + row.list_patient_details.LastName}</TableCell>
                <TableCell align="center">{row.list_patient_details.mobile}</TableCell>
                <TableCell align="center">
                    <MuiButton disableElevation variant="outlined" sx={{ borderRadius: "50px" }} onClick={() => handleOpenChat(guid)}>Chat </MuiButton>
                </TableCell>
            </TableRow>
        )
    }) : (
        <TableRow sx={{ 'td, th': { border: 0, padding: "10px" } }}>
            <TableCell align="center">Data not found</TableCell>
        </TableRow>
    )

    return (
        <Table columns={columns} tableData={renderTableData} loading={loading} />
    )
}

export default Patients;