import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import MyTeam from "../../components/doctor/chat/MyTeam";
import Patients from "../../components/doctor/chat/Patients";
import { useDispatch, useSelector } from "react-redux";
import { getMyTeamDoctorList, getPatientsList } from "../../redux/doctor/chat";
import { set_chat_window_ids } from "../../redux/features/feedback";

const ChatContainer = () => {
  const [value, setValue] = useState("1");
  const { myteam_list, patients_list, myteam_is_loading, patients_is_loading } =
    useSelector((state) => state.chat);
  const { doctor_id } = useSelector((state) => state.doctor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === "1") {
      dispatch(getMyTeamDoctorList());
    } else {
      dispatch(getPatientsList({ doctor_id }));
    }
  }, [dispatch, value]);

  const openChat = (id, type) => {
    try {
      if (type === "MYTEAM") {
        dispatch(set_chat_window_ids({ uid: id }));
      } else if (type === "PATIENT") {
        dispatch(set_chat_window_ids({ guid: id }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tableDetails =
    value === "1" && myteam_list?.rows
      ? myteam_list.rows
      : value === "2" && patients_list?.rows
      ? patients_list.rows
      : [];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, color: "#071B2A", fontWeight: "400" }} elevation={0}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="My Team" value="1" />
                <Tab label="Patients" value="2" />
              </Tabs>
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <MyTeam
                teamDetails={tableDetails}
                openChat={openChat}
                loading={myteam_is_loading}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <Patients
                patientsDetails={tableDetails}
                openChat={openChat}
                loading={patients_is_loading}
              />
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChatContainer;
