import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Views
import DashboardView from "../pages/doctor/Dashboard.js";
import HistoryView from "../pages/doctor/History.js";
import ScheduleView from "../pages/doctor/Schedule.js";
import ChatView from "../pages/doctor/Chat.js";
import ProfileView from "../pages/doctor/Profile.js";
import { isAuth } from "../helpers/cookies.js";
import { updatePlayerID } from "../redux/doctor/dashboard.js";
import { useDispatch, useSelector } from "react-redux";
import BackdropComponent from "../cometchat/components/Backdrop.jsx";

const DoctorRoutes = () => {
  const { player_id_loading } = useSelector((state) => state.doctor);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.OneSignal?.isPushNotificationsEnabled) {
      const old_player_id = isAuth().player_id;
      window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (isEnabled) {
          // user has subscribed
          window.OneSignal.getUserId(function (player_id) {
            if (old_player_id !== player_id)
              dispatch(updatePlayerID({ player_id }));
            console.log("player_id of the subscribed user is : " + player_id);
            // Make a POST call to your server with theF user ID
          });
        }
      });
    }
  }, []);
  return (
    <>
      <BackdropComponent open={player_id_loading} />
      <Routes>
        <Route exact path="/" element={<DashboardView />} />
        <Route exact path="/dashboard" element={<DashboardView />} />
        <Route exact path="/history" element={<HistoryView />} />
        <Route exact path="/schedule" element={<ScheduleView />} />
        <Route exact path="/chat" element={<ChatView />} />
        <Route exact path="/profile" element={<ProfileView />} />
      </Routes>
    </>
  );
};

export default DoctorRoutes;
