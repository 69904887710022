import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import VerifyOtpForm from "../components/Auth/VerifyOtpForm";
import AuthAsidepanel from "../components/Auth/AuthAsidepanel";
import LoginForm from "../components/Auth/LoginForm";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../helpers/cookies";
import { useDispatch, useSelector } from "react-redux";
import { setDoctorId } from "../redux/doctor/dashboard";

const LoginContainer = () => {
  const [counter, setCounter] = useState(10);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [email, set_email] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const checkUserLoggedIn = () => {
      const user = isAuth();

      switch (user.role_name) {
        case "DOCTOR":
          dispatch(setDoctorId(user.uid));
          navigate("/doctor/schedule");
          break;
        case "NURSE":
          dispatch(
            setDoctorId(
              user.doctors_mapped.length > 0
                ? user.doctors_mapped[0].uid
                : false
            )
          );
          navigate("/doctor/schedule");
          break;
        case "SUPPORT":
          navigate("/support/dashboard");
          break;
        default:
          break;
      }
    };
    checkUserLoggedIn();
  }, [navigate, dispatch]);

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        xs={5.5}
        sx={{ backgroundColor: "#0098FF", display: "flex" }}
      >
        <AuthAsidepanel />
      </Grid>
      <Grid
        container
        item
        xs={6.5}
        justifyContent="center"
        alignItems="center"
        sx={{ display: "flex" }}
      >
        {!email ? (
          <LoginForm returnForm={(email) => set_email(email)} />
        ) : (
          <VerifyOtpForm
            returnForm={(email) => set_email(email)}
            email={email}
            counter={counter}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LoginContainer;
