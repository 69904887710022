import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

const SchedulePatientAppointementCard = (props) => {
  const { futureAppointmentList, loading } = props;
  return (
    <Grid item xs={3}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2.2,
          borderRadius: "10px",
        }}
      >
        {loading ? (
          <Skeleton variant="circular" width={46} height={46} />
        ) : (
          <img
            src={futureAppointmentList.img}
            width={46}
            height={46}
            alt={futureAppointmentList.alt}
          />
        )}
        <Box
          sx={{
            ml: 3.5,
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <Skeleton variant="text" width={40} />
          ) : (
            <Typography
              variant="h5"
              sx={{ color: "#1D9BFF", fontWeight: "bold" }}
            >
              {futureAppointmentList.count}
            </Typography>
          )}
          {loading ? (
            <Skeleton variant="text" width={80} />
          ) : (
            <Typography variant="subtitle2" sx={{ color: "#808591" }}>
              {futureAppointmentList.title}
            </Typography>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default SchedulePatientAppointementCard;
