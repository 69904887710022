import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const syncEaglePatient = createAsyncThunk(
  "syncEaglePatient",
  async (payload, { rejectWithValue }) => {
    try {
      // const { doctor_id } = payload;
      // const endpoint = `${baseUrl}${patientList}?doctor_id=${doctor_id}&page=${1}&limit=${100}&type=${"DASHBOARD"}&date=${currentDate}`;
      // const response = await axios.get(endpoint);
      // return response.data;
      setTimeout( () => {
        return []
      } , 3000 );
      
    } catch (error) {
      rejectWithValue(error);
    }
  }
);


const initialState = {
  dark_mode: null,
  snackbar: {
    open: false,
    message: "",
    severity: "info",
  },
  chat_window_state: false,
  chat_window_uid: "",
  chat_window_guid: "",
  eagle_patient_is_loading : false
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    set_dark_mode: (state, action) => {
      state.dark_mode = action.payload
        ? action.payload
        : state.dark_mode === "light"
          ? "dark"
          : "light";
    },
    setSnackBar: (state, action) => {
      state.snackbar = action.payload;
    },
    set_chat_window_state: (state) => {
      state.chat_window_state = !state.chat_window_state;
    },
    set_chat_window_ids: (state, action) => {
      const { uid, guid } = action.payload;
      if (uid) {
        state.chat_window_uid = uid;
        state.chat_window_guid = "";
        state.chat_window_state = true;
      }
      if (guid) {
        state.chat_window_uid = "";
        state.chat_window_guid = guid;
        state.chat_window_state = true;
      }
    },
  },
  extraReducers: {
    //sync eagle patient
    [syncEaglePatient.pending]: (state, action) => {
      state.eagle_patient_is_loading = true;
    },
    [syncEaglePatient.fulfilled]: (state, action) => {
      state.eagle_patient_is_loading = false;
    },
    [syncEaglePatient.rejected]: (state, action) => {
      state.eagle_patient_is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_dark_mode,
  setSnackBar,
  set_chat_window_state,
  set_chat_window_ids,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
