import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  baseUrl,
  myTeamDoctorList,
  patientsList,
} from "../../helpers/constants";
import { axios } from "../../helpers/Server";

export const getMyTeamDoctorList = createAsyncThunk(
  "getMyTeamDoctorList",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${myTeamDoctorList}?&page=${1}&limit=${100}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getPatientsList = createAsyncThunk(
  "getPatientsList",
  async (payload, { rejectWithValue }) => {
    try {
      const endpoint = `${baseUrl}${patientsList}?DoctorId=${
        payload.doctor_id
      }&page=${1}&limit=${100}`;
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const initialState = {
  myteam_list: [],
  myteam_is_loading: true,
  patients_list: [],
  patients_is_loading: true,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: {
    //myteam list
    [getMyTeamDoctorList.pending]: (state, action) => {
      state.myteam_is_loading = true;
    },
    [getMyTeamDoctorList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.myteam_list = data;
      state.myteam_is_loading = false;
    },
    [getMyTeamDoctorList.rejected]: (state, action) => {
      state.myteam_is_loading = false;
    },

    //patients list
    [getPatientsList.pending]: (state, action) => {
      state.patients_is_loading = true;
    },
    [getPatientsList.fulfilled]: (state, action) => {
      let data = action.payload;
      state.patients_list = data;
      state.patients_is_loading = false;
    },
    [getPatientsList.rejected]: (state, action) => {
      state.patients_is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
// export const {} = chatSlice.actions;

export default chatSlice.reducer;
