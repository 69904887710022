import { Button as MuiButton } from "@mui/material";
import React from "react";

const Button = (props) => {
  const { children, color, variant, onClick, className, type, ...other } =
    props;
  return (
    <MuiButton
      className={className || ""}
      variant={variant || "contained"}
      color={color}
      onClick={onClick}
      type={type}
      {...other}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
